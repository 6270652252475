import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip, Typography, } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import LoadingOverlay from "@ronchalant/react-loading-overlay";
import { useRecoilState } from "recoil";
import { globalSpinner } from "../../recoil/profile-atom";
import { KSArrowLeftIcon } from "../../icons/custome-icon";

export const DialogCommonHooks = (props) => {
  let [spinner, setSpinner] = useRecoilState(globalSpinner);
  return (
    <Dialog
      fullWidth
      maxWidth={props.size}
      open={props.show}
      aria-labelledby="customized-dialog-title"
    >
      <DialogTitle
        sx={{ fontWeight: "bold", display: props?.title ? "" : "none", paddingRight: '32px' }}
      >
        {props?.title}

        <IconButton
          aria-label="close"
          onClick={props.handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "white",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>


      <IconButton
        aria-label="close"
        onClick={props.handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          color: "black",
        }}
      >
        <CloseIcon sx={{ display: props?.onlyCloseIcon ? null : "none" }} />
      </IconButton>


      <DialogContent style={{ borderTop: '7px solid #2F80ED' }}>
        <LoadingOverlay
          active={spinner}
          spinner={true}
          text="Please wait a moment"
        >{props.body}
        </LoadingOverlay>
      </DialogContent>
      {props.footer ? (
        <DialogActions sx={{ py: 3 }}>{props.footer}</DialogActions>
      ) : null}
    </Dialog>
  );
};



export const PreviewPDFModal = (props) => {
  return (
    <Dialog
      fullWidth
      maxWidth={props.size}
      open={props.show}
      aria-labelledby="File-Preview"
      PaperComponent={Box}
    >
      <DialogContent sx={{ p: 0 }}>
        <Box pb={5} position={'relative'}>
          <Tooltip title={'Close'}>
            <IconButton
              aria-label="close"
              sx={{
                position: "absolute",
                left: 16,
                top: 0,
                p: 0.5,
                color: "white"
              }}
            >
              <KSArrowLeftIcon onClick={props.handleClose} />
              <Typography pl={1}>
                {"Generated Pre-approval/Quote"}
              </Typography>
            </IconButton>
          </Tooltip>
        </Box>
        {props.body}
      </DialogContent>
      {props.footer ? (
        <DialogActions sx={{ py: 3 }}>{props.footer}</DialogActions>
      ) : null}
    </Dialog>
  );
};


