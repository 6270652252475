import {
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  Tooltip,
} from "@mui/material";
import React, { useMemo, useState, useEffect } from "react";
import MainLoyOut from "../../../components/main-layout";
import CustomReactMaterialTable from "../../../helpers/table/react-material-table";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import { useNavigate } from "react-router-dom";
import { Path } from "../../../routes/route-config";
import { API } from "../../../services/api-config";
import { KSDocumentSearchIcon, KSMailIcon } from "../../../icons/custome-icon";
import { TimeStampToMonthDateYear } from "../../../helpers/time-formater";
import { CustomSwitch } from "../../../helpers/custom-switch";
import { kstheme } from "../../../theme/theme";
import { openMail } from "../../../helpers/common_fuctions/open-mail";
import { getApiCallDynamic } from "../../../services/api-service";
import { toast } from 'react-toastify';

export default function Leads() {
  const router = useNavigate();

  const isTab = useMediaQuery(kstheme.breakpoints.down("md"));
  const [togleState, setToggleState] = useState(false);
  let assignSwitch;


  const handleForm = ({ state }) => {
    assignSwitch = state;



  };

  const handleSwitchChange = (checked) => {
    setToggleState(checked);
    assignSwitch = checked;

    console.log("command" + assignSwitch);

  };




  const handleClick = ({ row }) => {





    if (row?.original?.requestedInfo === "Pre-approval") {

      handlePreapprovalForm(row.original.id);
    } else if (row?.original?.requestedInfo === "Quote") {

      handleQuoteForm(row.original.id)
    } else if (row?.original?.requestedInfo === "Quote & Pre-approval") {

      if (assignSwitch === undefined) {
        handleQuoteForm(row.original.id)

      }
      else if (assignSwitch === false) {
        handlePreapprovalForm(row.original.id);

      }

    }

  };


  const handleSentClick = ({ row }) => {

    let leadType = null;
    if (assignSwitch === undefined)

      leadType = "Quote";

    else if (assignSwitch === false) leadType = "Pre-approval";
    else leadType = "Quote";







    getApiCallDynamic({ path: API.leadSend, param: { leadId: row?.original?.id, leadType: leadType } }).then((res) => {
      if (res?.status === 200) {
        toast.success("Successfully sent!", { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
      } else {
        toast.error("Failed to send. Please try again.", { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
      }

    }).catch((error) => {
      toast.error("Network error. Please try again later.", { autoClose: 3000, position: toast.POSITION.TOP_RIGHT });
    });


  }

  const handlePreapprovalForm = (id) => {
    router(Path.preapprovalForm + "/" + id);
  };

  const handleQuoteForm = (id) => {
    router(Path.quoteForm + "/" + id);
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "Lead ID",
        enableColumnActions: false,
        enableSorting: false,
        size: 0,
      },

      {
        accessorKey: "info",
        header: "LEAD INFO",
        enableColumnActions: false,
        enableSorting: false,
        size: 0,
        Cell: ({ row }) => (
          <>
            <Typography>
              {`${row.original.leadInfo.firstName} ${row.original.leadInfo.lastName}`}
            </Typography>
            <Typography>{`${row.original.leadInfo.phone}`}</Typography>
            <Typography>{`${row.original.leadInfo.email}`}</Typography>
          </>
        ),
      },
      {
        accessorKey: "mloName",
        header: "MLO",
        enableColumnActions: false,
        enableSorting: false,
        size: 0,
      },
      {
        accessorKey: "propertyType",
        header: "Property Type",
        enableColumnActions: false,
        enableSorting: false,
        size: 0,
      },
      {
        accessorKey: "requestedInfo",
        header: "Requested info",
        enableColumnActions: false,
        enableSorting: false,
        size: 0,
        Cell: ({ row }) => (
          <>

            <Typography fontSize={'14px'} lineHeight={'1.43'} color={'rgba(0, 0, 0, 0.87)'}>{row.original.requestedInfo}</Typography>
            {row.original.leadInfo?.message &&
              <Box display={'flex'} width={'100px'} color={'#222222BF'}>

                <Tooltip
                  title={row.original.leadInfo?.message}
                  placement="right"
                  arrow
                  enterDelay={500}
                  leaveDelay={200}
                >
                  <iconButton>
                    <KSDocumentSearchIcon />
                  </iconButton>
                </Tooltip>


              </Box>
            }
          </>
        ),
      },
      {
        accessorKey: "id",
        header: "Action",
        enableColumnActions: false,
        enableSorting: false,
        Cell: ({ cell, row }) => {
          const [test, setTest] = useState();
          return row?.original?.requestedInfo == "Contact Me" ? (
            <Box display={"flex"} justifyContent={"left"} pl={2} >
              <IconButton onClick={(e) => {
                openMail(row.original.leadInfo.email);
              }}>
                <KSMailIcon />
              </IconButton>
            </Box>
          ) : (
            <Box display={"flex"} justifyContent={"left"}>
              <Box
                display={"flex"}

                flexDirection={"column"}
              >
                <Stack direction={"row"} gap={1} alignItems={"center"}>
                  <Typography sx={{ minWidth: "max-content" }}>
                    Pre-approval
                  </Typography>
                  <CustomSwitch
                    disabled={
                      row?.original?.requestedInfo == "Pre-approval" ||
                        row?.original?.requestedInfo == "Quote"
                        ? true
                        : false
                    }
                    defaultValue={
                      row?.original?.requestedInfo == "Pre-approval"
                        ? false
                        : true
                    }
                    callBackForSwitch={handleForm}
                    checkedValue={handleSwitchChange}
                  />
                  <Typography>Quote</Typography>
                </Stack>
                <Box>
                  <Button
                    key={cell.getValue()}
                    size="small"
                    variant="outlined"
                    endIcon={<SendOutlinedIcon />}
                    onClick={(event) => {
                      handleClick(cell, row);
                    }}
                    style={{ marginRight: '10px', letterSpacing: 0 }}
                  >
                    Generate
                  </Button>

                  <Button
                    key={cell.getValue()}
                    size="small"
                    variant="outlined"

                    onClick={(event) => {
                      handleSentClick(cell, row);
                    }}
                    style={{ letterSpacing: 0 }}
                  >
                    Send
                  </Button>
                </Box>
                <Typography
                  sx={{ color: "#999999", fontWeight: "400", fontSize: "10px" }}
                >
                  {row?.original?.lastSentDate
                    ? `Last generated on ` +
                    TimeStampToMonthDateYear(row?.original?.lastSentDate)
                    : ""}{" "}
                </Typography>

                <Box>

                </Box>
              </Box>

            </Box>
          );
        },
      },
      //end
    ],
    []
  );

  let data = [
    {
      id: "1",
      leadInfo: "sal",
      mloName: "Mlo Name",
      propertyType: "ok",
      requestType: "Quote",
    },
    {
      id: "2",
      leadInfo: "sal",
      mloName: "Mlo Name2",
      propertyType: "ok",
      requestType: "Pre-approval",
    },
    {
      id: "3",
      leadInfo: "sal",
      mloName: "Mlo Name3",
      propertyType: "ok",
      requestType: "Quote & Pre-approval",
    },
  ];

  return (
    <MainLoyOut>
      <CustomReactMaterialTable

        enableToolbarInternalActions={false}
        enablePagination={false}
        enableColumnFilters={false}
        endPoint={API.leads}
        columns={columns}
        showGlobalFilter={true}
        muiSearchTextFieldProps={{
          placeholder: isTab ? 'Search' : "Search for Leads",
          variant: "outlined",
        }}
      />
    </MainLoyOut>
  );
}
