
import { Box, Grid, Checkbox, FormControlLabel, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import CustomCheckboxFileUpload from '../../../../../helpers/custom-checkbox-file-upload'
import DocumentStatusCard from './document-status-card'
import { TimeStampToMonthDateYear } from '../../../../../helpers/time-formater';

export default function UploadDocumentCard({
    title, description, data, control, fileUpload, isReview = false, setUpdateState, errors,
    primaryBorrowerName, primaryBorrowerPartnerName, skipSegmentRev
}) {



    const [skipSegment, setSkipSegment] = useState(false);
    const [skipSegmentCliked, setSkipSegmentClicked] = useState(false);
    const [checkedTitle, setCheckedTitle] = useState(null);

    const changeCheck = (id, singleClick, singleCheckValue) => {


        if (skipSegmentCliked && skipSegment === true) {
            if (data[id].fileName === null)
                data[id].isSkipped = true;
        }
        if (skipSegmentCliked && skipSegment === false) {
            data[id].isSkipped = false;
        }

        if (skipSegmentCliked === false) {
            data[id].isSkipped = false;
        }

        if (singleClick && singleCheckValue === true) {
            if (data[id].fileName === null)
                data[id].isSkipped = true;
        }
        if (singleClick && singleCheckValue === false) {
            data[id].isSkipped = false;
        }



    }


    const handleSkipChange = (event) => {

        setSkipSegmentClicked(true)
        setSkipSegment(event.target.checked);


    };
    console.log("data " + JSON.stringify(data, null, 2))

    useEffect(() => {
        const allSkippedFalse = data.every(item => !item.isSkipped);
        if (allSkippedFalse) {
            setSkipSegment(false);
        }
        else {
            if (skipSegmentRev === false)
                setSkipSegment(true);

        }
    }, [data]);

    console.log(" hiiii " + JSON.stringify(data, null, 2))

    return (
        <>


            <Box my={3} key={title}>
                {/* <Grid container alignItems="center">
                    <Grid item>
                        <FormControlLabel
                            control={<Checkbox checked={skipSegment} onChange={handleSkipChange} />}

                        />

                    </Grid>

                    <Grid item style={{ marginLeft: '10px' }}>
                        <Typography variant='h5' color='primary' gutterBottom>
                            {title.includes("Partner") ? title.replace("Partner", primaryBorrowerPartnerName) : title + primaryBorrowerName}

                        </Typography>
                    </Grid>


                    <Grid item xs={12} ml={7.5}>
                        <Typography gutterBottom>
                            {description}
                        </Typography>
                    </Grid>
                </Grid> */}
                <Grid container alignItems="left" sx={{ flexWrap: 'nowrap' }}>
                    <Grid item>
                        <FormControlLabel
                            control={<Checkbox checked={skipSegment} onChange={handleSkipChange} />}
                        />
                    </Grid>

                    <Grid item sx={{ marginLeft: '10px', marginTop: '10px' }}>
                        <Typography variant='h5' color='primary' gutterBottom>
                            {title.includes("Partner") ? title.replace("Partner", primaryBorrowerPartnerName) : title + primaryBorrowerName}
                        </Typography>



                        <Typography gutterBottom>
                            {description}
                        </Typography>
                    </Grid>
                </Grid>


                {data.map((item, index) => (
                    <>
                        {item.uploadDate ?
                            <DocumentStatusCard
                                key={item.documentName}
                                control={control}
                                title={item.documentName}
                                fileName={item.fileName}
                                info={item.info}
                                docIc={item.id}
                                uploadTime={TimeStampToMonthDateYear(item.uploadDate)}
                                status={item.textractStatus}
                                fileUpload={fileUpload}
                                isReview={isReview}
                                isOverridden={item.isOverridden}
                                validStatus={item.validStatus}
                                overrideReason={item.overrideReason}
                                setUpdateState={setUpdateState}
                                remark={item.textractRemarks}
                                isManualReview={item.isManualReview}
                                skipSegment={skipSegment}
                            />
                            :

                            <CustomCheckboxFileUpload
                                id={index}
                                key={item.documentName}
                                control={control}
                                errors={errors}
                                info={item.info}
                                title={item.documentName}
                                docIc={item.id}
                                checkData={item.isSkipped}
                                changeCheck={changeCheck}
                                fileUpload={fileUpload}
                                isReview={isReview}
                                data={data}
                                skipSegment={skipSegment}
                                skipSegmentCliked={skipSegmentCliked}
                                skipSegmentRev={skipSegmentRev}

                            />

                        }
                    </>
                ))}


            </Box>
        </>
    )
}
