// import React, { useState, useEffect, useContext } from 'react'
// import { makeStyles } from "@mui/styles";
// import { Paper, Grid, Typography, Divider, Box, Button, Table, TableBody, TableCell, TableHead, TableRow, TableContainer } from "@mui/material";
// import axios from 'axios';
// import Logo from "../../../assets/Cliqloan-logo 1.png"
// import { BillContext } from "./BillContext";
// import Invoice from './invoice';




// const useStyles = makeStyles((theme) => ({


//   aspectRatioContainer: {
//     position: 'relative',
//     width: '100%',
//     // paddingTop: '141.42%', /* 1 / 0.7071 (inverse of the aspect ratio) */
//     paddingTop: '200%', /* 1 / 0.7071 (inverse of the aspect ratio) */
//   },

//   content: {
//     position: 'absolute',
//     top: 0,
//     left: 0,
//     right: 0,
//     bottom: 0,

//   },




//   invoiceBorder: {

//     padding: theme.spacing(5),

//   },
//   root: {
//     padding: theme.spacing(5),
//     border: '1px solid #DBDBDB',
//     borderTop: '8px solid #007bff', // Blue border on top with a width of 4 pixels

//   },
//   outerBox: {
//     padding: theme.spacing(0),
//     marginBottom: theme.spacing(2),
//     backgroundColor: '#ffffff',
//     //border: '2px solid blue',
//   },
//   outerSecondBox: {
//     padding: theme.spacing(0),
//     marginBottom: theme.spacing(2),
//     //border: '2px solid pink',
//   },
//   innerBox: {
//     padding: theme.spacing(2),
//     //backgroundColor: '#f5f5f5', // Change this to light gray (#f5f5f5)
//     //borderRadius: 5,
//     //boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
//     //border: '2px solid red',
//     marginBottom: theme.spacing(2),

//   }, rowInvoice: {

//     justifyContent: "space-between",
//     alignItems: "center",
//     padding: '8px 16px',
//     textAlign: 'right',
//     borderBottom: '1px solid #DBDBDB',

//   }, rowInvoiceMix: {

//     justifyContent: "space-between",
//     alignItems: "center",
//     padding: '8px 16px',
//     textAlign: 'right',
//     borderTop: '1px solid #DBDBDB',
//     borderBottom: '1px solid #DBDBDB',

//   }, rowUserType: {

//     justifyContent: "space-between",
//     alignItems: "center",
//     padding: '8px 16px',
//     textAlign: 'right',
//     borderBottom: '1px solid #DBDBDB',

//   }, rowInvoiceWithoutBorder: {

//     justifyContent: "space-between",
//     alignItems: "center",
//     padding: '8px 8px',
//     textAlign: 'right',

//   },
//   invoiceItemLevel: {

//     fontWeight: 700,
//     fontSize: '14px',
//     lineHeight: '14.52px',
//   },

//   invoiceItemValue: {

//     fontWeight: 400,
//     fontSize: '14px',
//     lineHeight: '18.23px',
//   },
//   rightAlign: {
//     textAlign: 'right',
//   },
//   separator: {
//     margin: theme.spacing(2, 0),
//   },
//   downloadButton: {
//     marginTop: theme.spacing(2),
//   },






//   //

//   table: {
//     minWidth: 650,
//     // borderCollapse: 'separate', // Separate border spacing

//     "& .MuiTableCell-root": {
//       border: '1px solid black'
//     }
//   },
//   row: {

//     // border: '1px solid black', // Border at the top and bottom of each row
//   },
//   cell: {
//     padding: '8px',
//     border: 'none'
//   },


//   // Styles for invoice item name
//   itemNameStyle: {
//     fontWeight: 700,
//     fontSize: '14px',
//     lineHeight: '14.52px',
//     textAlign: 'left',

//   },

//   // Styles for invoice item value
//   itemValueStyle: {
//     fontWeight: 400,
//     fontSize: '14px',
//     lineHeight: '18.23px',
//     textAlign: 'right',
//     paddingRight: '16px',
//     paddingLeft: '8px',
//     paddingTop: '8px',
//     paddingBottom: '4px',
//   },



// }));

// function CurrentInvoice() {
//   const classes = useStyles();

//   const { isNewUser, userCode } = useContext(BillContext);

//   //let userCode;
//   let isMounted = true;

//   const [loading, setLoading] = useState(true);
//   const [invoiceDetails, setInvoiceDetails] = useState({});
//   const [cItem, setCItem] = useState({});  // json inside json
//   const [disItem, setDisItem] = useState({});
//   const [invoiceItem, setInvoiceItem] = useState([]);  // array inside json

//   const [invoiceNumber, setInvoiceNumber] = useState(null);



//   useEffect(() => {


//     const BASE_URL = "https://stage.kramasoft.com/ks-bill/api/v1/invoice"
//     const INVOICE_URL = "current-month-invoice?userCode=";
//     //const CURRENT_INVOICE_ID = "52fqo9bknq_1676555997907";  // With Coupon
//     const CURRENT_INVOICE_ID = userCode;  // With Coupon
//     const INVOICE_API_URL = `${BASE_URL}/${INVOICE_URL}${CURRENT_INVOICE_ID}`;


//     //Final
//     axios
//       .get(INVOICE_API_URL)
//       .then((response) => {
//         if (isMounted) {
//           setInvoiceDetails(response.data);
//           setCItem(response.data.cItem);
//           setDisItem(response.data.disItem)
//           setInvoiceItem(response.data.invoiceItem);

//           setInvoiceNumber(response.data.invoiceNumber);



//           setLoading(false);
//           console.log("Invoice Details: ", response.data);
//           console.log("Current Invoice Url: ", INVOICE_API_URL);
//         }

//       })
//       .catch((error) => {
//         if (isMounted) {
//           console.error("Error fetching Invoice Details:", error);
//           setLoading(false);
//         }
//       });
//   }, []);


//   // InvoiceNumber 
//   useEffect(() => {
//     console.log("Invoice Number " + invoiceNumber);
//   }, [invoiceNumber]);


//   if (invoiceNumber === null || typeof invoiceNumber === 'undefined') {
//     return null;
//   }

//   return (

//     invoiceNumber !== null ? (


//       <div className={classes.aspectRatioContainer}>
//         <div className={classes.content}>
//           <div className={classes.invoiceBorder}>


//             <div className={classes.root}>



//               <Invoice invoiceDetails={invoiceDetails} />



//             </div>

//             <Grid container spacing={2}>
//               <Grid item xs={6}>
//                 {/* Left side */}

//               </Grid>
//               <Grid item xs={6} className={classes.rightAlign}>
//                 {/* Download button */}
//                 <Button
//                   variant="contained"
//                   color="primary"
//                   className={classes.downloadButton}
//                 // onClick={handleDownloadPDF}
//                 >
//                   Download
//                 </Button>
//               </Grid>
//             </Grid>
//           </div>
//         </div>
//       </div>

//     ) : ""


//   );
// }



// export default CurrentInvoice;
import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from "@mui/styles";
import { Paper, Grid, Stack, Typography, Divider, Box, Button, Table, TableBody, TableCell, TableHead, TableRow, TableContainer } from "@mui/material";
import axios from 'axios';
import Logo from "../../../assets/Cliqloan-logo 1.png"
import { BillContext } from "./BillContext";
import Invoice from './invoice';
import { API } from '../../../services/api-config'






function CurrentInvoice({ userCode }) {
  //const classes = useStyles();

  const { isNewUser } = useContext(BillContext);

  //let userCode;
  let isMounted = true;

  const [loading, setLoading] = useState(true);
  const [invoiceDetails, setInvoiceDetails] = useState({});
  const [cItem, setCItem] = useState({});  // json inside json
  const [disItem, setDisItem] = useState({});
  const [invoiceItem, setInvoiceItem] = useState([]);  // array inside json

  const [invoiceNumber, setInvoiceNumber] = useState(null);



  useEffect(() => {


    // const BASE_URL = "https://stage.kramasoft.com/ks-bill/api/v1/invoice"
    const BASE_URL = API.billInvoice
    const INVOICE_URL = "current-month-invoice?userCode=";
    //const CURRENT_INVOICE_ID = "52fqo9bknq_1676555997907";  // With Coupon
    const CURRENT_INVOICE_ID = userCode;  // With Coupon
    const INVOICE_API_URL = `${BASE_URL}/${INVOICE_URL}${CURRENT_INVOICE_ID}`;


    //Final
    axios
      .get(INVOICE_API_URL)
      .then((response) => {
        if (isMounted) {
          setInvoiceDetails(response.data);
          setCItem(response.data.cItem);
          setDisItem(response.data.disItem)
          setInvoiceItem(response.data.invoiceItem);
          setInvoiceNumber(response.data.invoiceNumber);
          setLoading(false);
          console.log("Invoice Details: ", response.data);
          console.log("Current Invoice Url: ", INVOICE_API_URL);
        }

      })
      .catch((error) => {
        if (isMounted) {
          console.error("Error fetching Invoice Details:", error);
          setLoading(false);
        }
      });
  }, []);

  const handleDownloadPDF = () => {
    if (invoiceNumber) {
      const PDF_GENERATE_URL = `${API.downloadInvoice}/generatePdf?invoiceNumber=${invoiceNumber}`;
      axios.get(PDF_GENERATE_URL, { responseType: 'blob' })
        .then((response) => {
          // Create a Blob from the PDF data received
          const blob = new Blob([response.data], { type: 'application/pdf' });
          // Create a link element to download the PDF
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute('download', 'invoice.pdf');
          document.body.appendChild(link);
          link.click();
          // Cleanup
          document.body.removeChild(link);
        })
        .catch((error) => {
          // Handle error in PDF generation
          console.error("Error generating PDF:", error);
        });
    }
  };

  // InvoiceNumber 
  useEffect(() => {
    console.log("Invoice Number " + invoiceNumber);
  }, [invoiceNumber]);


  if (invoiceNumber === null || typeof invoiceNumber === 'undefined') {
    return (
      <Box textAlign="center">
        <Typography variant="h5">Invoice will be generated on the 1st of each month. Please check back accordingly for updates.</Typography>
      </Box>
    );
  }

  return (

    invoiceNumber !== null ? (


      <>
        {/* <Stack direction={'column'} justifyContent={'space-between'} py={2} px={4} height={'inherit'} overflow={'auto'}> */}

        <Invoice invoiceDetails={invoiceDetails} />


        <Box marginTop={3} style={{ textAlign: 'right' }}>

          <Button
            variant="contained"
            color="primary"
            //className={classes.downloadButton}
            onClick={handleDownloadPDF}
          >
            Download
          </Button>
        </Box>
        {/* </Stack> */}
      </>) : ""


  );
}



export default CurrentInvoice;
