/**
 * Path definition to route between JavaScript pages.
 * Paths can be accessed through Path's static
 * members, e.g., Path.index etc.
 * @type {string}
 */
const INDEX = "/";
const NOTFOUND = "/notfound";
const SIGNIN = "/signin";
const REGISTRATION = "/signup";
const LANDING = "/landing";
const FORGET = "/forget";

const INVITATION_REGISTRATION = "/invitation-registration";
const INVITATION_REGISTRATION_SET_PASSWORD = "/invitation-set-password";
const OTP = "/otp";

const SET_PASSWORD = "/set-password";
const BROKER = "broker";

const INVITATION = "invitation";

const INVITATION_LIST = "invitation-list";
const TEAM_INVITATION_LIST = "team-invitation";
const SETTING = "settings";
const HELP_SUPPORT = "/support";

const BORROWER = "borrower";

const MLO = "_mlo";
const MLP = "mlp";

const DASHBOARD = "dashboard";

const LOAN = "loan";

const ADMIN = "admin";
const WELCOME = "welcome"
const DEMO_REQUEST = "demo-request";
const SUPPORT = "support";
const NOTES = "notes";
const LEADS = "leads";
const QUOTE_FORM = "quote-form";
const PREAPPROVAL_FORM = "preapproval-form";
const CREDCO_CREDENTIALS = "credco-credentials";
const ADDITIONAL_LOAN_CONTACT = "additional-loan-contact";
const LOAN_NOTES = "loan-notes";


// Loan Application Routing
const LOAN_APPLICATION = 'loan-application';
const LOAN_SUMMARY = "loan-summary"

const GETTING_STARTED = 'getting-started';
const GETTING_STARTED_CO_BORROWER = 'co-borrower';
const CONFIRM_PARTNER_CO_BORROWER = 'confirm-partner-co-borrower';

const LOAN_DETAILS = 'loan-details';

const LOAN_BORROWER_INFO = 'borrower-info'
const LOAN_PARTNER_INFO = 'partner-info'
const LOAN_PERSONAL_INFO = 'personal-information'
const LOAN_CONTACT_INFO = 'contact-information'
const LOAN_ADDITIONAL_ADDRESS = 'additional-address'

const LOAN_DEMOGRAPHICS = 'demographics'

const LOAN_DECLARATIONS = 'declarations'

const LOAN_DEPENDENT = 'dependent'

const LOAN_EMPLOYMENT = 'employment'
const LOAN_ADDITIONAL_INCOME = 'additional-income'
const LOAN_EXTRA_INCOME = 'extra-income'
const LOAN_SECONDARY_EMPLOYMENTS = 'secondary-employments'
const LOAN_PREVIOUS_EMPLOYMENTS = 'previous-employments'
const LOAN_MILITARY_SERVICE = 'military-service'

const LOAN_ASSETS_CREDITS = 'assets-credits'

const LOAN_REAL_ESTATE = 'real-estate'
const LOAN_GIFT_GRANTS = 'gift-grants'

const LOAN_CREDIT_REPORT = 'credit-report'
const LOAN_CREDIT_REPORT_STATUS = 'credit-report-status'

const LOAN_LIABILITIES = 'liabilities'

const LOAN_DOCUMENTS = 'documents'
const LOAN_GUIDED_DOCUMENT = 'guided-document'
const LOAN_ADDITIONAL_DOCUMENT = 'additional-document'

const LOAN_REVIEW = 'review'

const LOAN_SUBMIT = 'submit'
const MLO_LOAN_DOCUMENT = 'loan-document'

const LOAN_FORM_APPLY = '/borrower/loan-application/'
const MLO_WEBSITE_APPLY = '/apply'

const LOAN_APP_SUMMARY = '/loan-application/loan-summary';

export class Path {

  static get mloWebsiteApply() {
    return MLO_WEBSITE_APPLY;
  }
  static get loanFormApply() {
    return LOAN_FORM_APPLY
  }
  static get additionalLoanContact() {
    return ADDITIONAL_LOAN_CONTACT;
  }

  static get loanNotes() {
    return LOAN_NOTES;
  }

  static get credcoCredentials() {
    return CREDCO_CREDENTIALS;
  }

  static get preapprovalForm() {
    return PREAPPROVAL_FORM;
  }
  static get quoteForm() {
    return QUOTE_FORM;
  }
  static get leads() {
    return LEADS;
  }
  static get notes() {
    return NOTES;
  }
  static get support() {
    return SUPPORT;
  }
  static get demoRequest() {
    return DEMO_REQUEST;
  }
  static get admin() {
    return ADMIN;
  }
  static get welcome() {
    return WELCOME;
  }
  static get loan() {
    return LOAN;
  }
  static get mlo() {
    return MLO;
  }
  static get mlp() {
    return MLP;
  }
  static get invitationRegPass() {
    return INVITATION_REGISTRATION_SET_PASSWORD;
  }
  static get invitationReg() {
    return INVITATION_REGISTRATION;
  }
  static get dashboard() {
    return DASHBOARD;
  }
  static get borrower() {
    return BORROWER;
  }

  static get setting() {
    return SETTING;
  }
  static get helpSupport() {
    return HELP_SUPPORT;
  }
  static get invitation() {
    return INVITATION;
  }

  static get teamInvitationList() {
    return TEAM_INVITATION_LIST;
  }

  static get invitationList() {
    return INVITATION_LIST;
  }

  static get broker() {
    return BROKER;
  }

  static get setPassword() {
    return SET_PASSWORD;
  }
  static get otp() {
    return OTP;
  }
  static get landing() {
    return LANDING;
  }
  static get index() {
    return INDEX;
  }

  static get forget() {
    return FORGET;
  }
  static get signin() {
    return SIGNIN;
  }

  static get signup() {
    return REGISTRATION;
  }

  static get notFound() {
    return NOTFOUND;
  }

  // Loan Application
  static get loanApplication() {
    return LOAN_APPLICATION;
  }

  static get loanSummary() {
    return LOAN_SUMMARY;
  }

  static get gettingStarted() {
    return GETTING_STARTED;
  }

  static get gettingStartedCoBorrower() {
    return GETTING_STARTED_CO_BORROWER;
  }

  static get confirmPartnerCoBorrower() {
    return CONFIRM_PARTNER_CO_BORROWER;
  }

  static get loanDetails() {
    return LOAN_DETAILS;
  }

  static get loanBorrowerInfo() {
    return LOAN_BORROWER_INFO;
  }

  static get loanPartnerInfo() {
    return LOAN_PARTNER_INFO;
  }

  static get loanPersonalInfo() {
    return LOAN_PERSONAL_INFO;
  }

  static get loanContactInfo() {
    return LOAN_CONTACT_INFO;
  }

  static get loanAdditionalAddress() {
    return LOAN_ADDITIONAL_ADDRESS;
  }

  static get loanDemographics() {
    return LOAN_DEMOGRAPHICS;
  }

  static get loanDeclarations() {
    return LOAN_DECLARATIONS;
  }

  static get loanDependent() {
    return LOAN_DEPENDENT;
  }

  static get loanEmployment() {
    return LOAN_EMPLOYMENT;
  }

  static get loanAdditionalIncome() {
    return LOAN_ADDITIONAL_INCOME;
  }

  static get loanExtraIncome() {
    return LOAN_EXTRA_INCOME;
  }

  static get loanSecondaryEmployments() {
    return LOAN_SECONDARY_EMPLOYMENTS;
  }

  static get loanPreviousEmployments() {
    return LOAN_PREVIOUS_EMPLOYMENTS;
  }

  static get loanMilitaryService() {
    return LOAN_MILITARY_SERVICE;
  }

  static get loanAssetsCredits() {
    return LOAN_ASSETS_CREDITS;
  }

  static get loanRealEstate() {
    return LOAN_REAL_ESTATE;
  }

  static get loanGiftGrants() {
    return LOAN_GIFT_GRANTS;
  }

  static get loanCreditReport() {
    return LOAN_CREDIT_REPORT;
  }

  static get loanCreditReportStatus() {
    return LOAN_CREDIT_REPORT_STATUS;
  }

  static get loanLiabilities() {
    return LOAN_LIABILITIES;
  }

  static get loanDocuments() {
    return LOAN_DOCUMENTS;
  }

  static get loanGuidedDocument() {
    return LOAN_GUIDED_DOCUMENT;
  }

  static get loanAdditionalDocument() {
    return LOAN_ADDITIONAL_DOCUMENT;
  }

  static get loanReview() {
    return LOAN_REVIEW;
  }

  static get loanSubmit() {
    return LOAN_SUBMIT;
  }

  static get mloLoanDocument() {
    return MLO_LOAN_DOCUMENT;
  }

  static get loanAppSummary() {
    return LOAN_APP_SUMMARY;
  }
}
