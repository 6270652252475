import React from "react";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { menuItems, menuItemsDown } from "./menu-item";
import PropTypes from "prop-types";
import { kstheme } from "../../theme/theme";

/**
 * This is a search field component with a dropdown list
 * @param {{
 * position: 'top' | 'bottom',
 * }} props
 */
function SideMenu({ role, position, navOpen = false, setOpen }) {
  // Used for checking current tab route
  let currentNavValue =
    location.pathname.split("/");

  // Used navigation auto hide on link change for mobile view
  const isMobile = useMediaQuery(kstheme.breakpoints.down("sm"));
  const mobileNavHideOnclick = () => (isMobile ? setOpen(false) : null);

  const router = useNavigate();

  // Side navbar position wise value change based on role
  let currentMenuList;
  switch (position) {
    case "top":
      currentMenuList = menuItems.filter((item) => item.roles.includes(role));
      break;

    case "bottom":
      currentMenuList = menuItemsDown.filter((item) =>
        item.roles.includes(role)
      );
      break;

    default:
      currentMenuList = null;
      break;
  }

  const currentNavigation = (
    <List sx={{ p: 0, mt: 2 }}>
      {currentMenuList.map((item) => (
        <ListItem
          key={item.id}
          sx={{
            padding: 0,
            backgroundColor: currentNavValue.includes(item.url) ? "#1A1C47" : null,
          }}
        >
          <ListItemButton
            onClick={() => {
              router(item.url);
              mobileNavHideOnclick();
            }}
          >
            <ListItemIcon>
              <item.icon sx={{ color: "white" }} />
            </ListItemIcon>
            <ListItemText sx={{ display: navOpen ? "block" : "none" }}>
              {item.label}
            </ListItemText>
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  );

  return currentNavigation;
}

SideMenu.propTypes = {
  role: PropTypes.string,
  position: PropTypes.string,
  navOpen: PropTypes.func,
  setOpen: PropTypes.func,
};

export default SideMenu;
