import { FormControl, FormControlLabel, Grid, Paper, Radio, RadioGroup, Typography, } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import MainLoyOut from "../../../components/main-layout";
import { TimeStampToMonthDateYear } from "../../../helpers/time-formater";
import { globalSpinner } from "../../../recoil/profile-atom";
import { API } from "../../../services/api-config";
import { getApiCallDynamic } from "../../../services/api-service";
import CredcoCredentialsForm from "./credco-credentials-form";
import CredcoCredentialsView from "./credco-credentials-view";
import { CREDCO_INFO, ROLE_LIST, UPDATE } from "../../../constants/constatnt-variable";
import { Auth } from "../../../auth/auth";


export default function CredcoCredentials() {
    let [spinner, setSpinner] = useRecoilState(globalSpinner);



    const [data, setData] = useState(null)

    let [roleVal, setRoleVal] = useState(null)
    const [hideStatus, setHideStatus] = useState(null)

    const [validationReset, setValidationReset] = useState(null)
    const [finalValidationReset, setFinalValidationReset] = useState(false)

    const getCredcoData = () => {
        setSpinner(true)

        getApiCallDynamic({ path: API.credcoCredentials }).then((res) => {
            if (res?.status === 200) {

                setData(res.data);

                setSpinner(false);
                setFinalValidationReset(false)
            }
        });
    }

    useEffect(() => {
        getCredcoData()

    }, []);



    useEffect(() => {
        if (validationReset === true)
            setFinalValidationReset(true)
        else
            setFinalValidationReset(false)

    }, [validationReset]);

    //console.log("role login " + roleVal)

    const [credcoEditSection, setCredcoEditSection] = useState(false);
    // console.log(" finalValidationReset. => " + finalValidationReset + "  data?.credcoStatus " + data?.credcoStatus)

    return (
        <MainLoyOut>
            <Paper>
                <Grid container justifyContent="space-between" p={{ xs: 2, md: 5 }}>
                    <Grid
                        item
                        container
                        md={4}
                        direction="column"
                        justifyContent={"space-between"}

                    >

                        {((roleVal === ROLE_LIST.mloOwner || roleVal === ROLE_LIST.broker) ||
                            (hideStatus === false || hideStatus === null))
                            && (
                                <Grid item>
                                    <Typography variant="body1" pb={2}>
                                        CredCo credentials will be encrypted and saved Securely in the Database.  Credentials will be validated once the Credentials are saved. Credit pull will not happen if the credentials are invalid.
                                    </Typography>
                                    <FormControl>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"

                                            name="radio-buttons-group"
                                        >
                                            <FormControlLabel

                                                checked={finalValidationReset === true || data?.credcoStatus === "Not validated"}
                                                //checked={"Not validated" == data?.credcoStatus}
                                                value="Not validated" control={<Radio />} label="Not validated" />
                                            <FormControlLabel
                                                checked={finalValidationReset === false && data?.credcoStatus === "Valid Credentials"}
                                                //   checked={"Valid Credentials" == data?.credcoStatus}

                                                value="Valid Credentials" control={<Radio />} label="Valid Credentials" />
                                            <FormControlLabel
                                                //checked={"Invalid Credentials" == data?.credcoStatus} 
                                                checked={finalValidationReset === false && data?.credcoStatus === "Invalid Credentials"}
                                                value="Invalid Credentials" control={<Radio />} label="Invalid Credentials" />

                                        </RadioGroup>

                                    </FormControl>

                                </Grid>)}
                        {(hideStatus === null || hideStatus === false) && (
                            <Grid item my={{ xs: 3, md: 0 }}>
                                <Typography variant="body2">
                                    Last Validated: {TimeStampToMonthDateYear(data?.lastValidatedDate)}
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                    <Grid item md={4}>
                        {

                            credcoEditSection ?
                                < CredcoCredentialsForm
                                    setCredcoEditSection={setCredcoEditSection}
                                    setData={setData}
                                    setRoleVal={setRoleVal}
                                    setValidationReset={setValidationReset}
                                    refreshData={getCredcoData}
                                    setHideStatus={setHideStatus}
                                    data={data} />
                                :

                                data?.credcoStatus !== "Not validated" ?
                                    < CredcoCredentialsView
                                        setCredcoEditSection={setCredcoEditSection}
                                        setValidationReset={setValidationReset}
                                        data={data}

                                    />
                                    :
                                    < CredcoCredentialsForm
                                        setCredcoEditSection={setCredcoEditSection}
                                        setData={setData}
                                        setValidationReset={setValidationReset}
                                        setHideStatus={setHideStatus}
                                        data={data}
                                        setRoleVal={setRoleVal}
                                        refreshData={getCredcoData}
                                    />
                        }

                    </Grid>
                </Grid>
            </Paper>
        </MainLoyOut >
    );
}
