import { ArrowBackIos, ArrowForwardIos, NavigateNext } from '@mui/icons-material'
import { Box, Breadcrumbs, Button, FormHelperText, Link as MLink, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { DialogCommonHooks } from '../../../../../../helpers/modal/modal'
import useInput from '../../../../../../helpers/use-input'
import { useRecoilState } from 'recoil'
import { formState } from '../../../../../../recoil/profile-atom'
import { useForm } from 'react-hook-form'
import SelfEmployed from './self-employed/self-employed'
import Employed from './epmloyed/epmloyed'
import Retired from './retired/retired'
import SecondaryEmployment from './secondary-employment/secondary-employment'
import LoanExtraIncome from './extra-income/extra-income'
import { API } from '../../../../../../services/api-config'
import { postApiCallDynamicWithOutReturn } from '../../../../../../services/api-service'
import { UPDATE } from '../../../../../../constants/constatnt-variable'
import { getMonthsBetweenDates } from '../../../../../../helpers/time-formater'

export default function LoanEmployment() {

    const route = useNavigate()
    const location = useLocation();
    const partnerInfoPath = location.pathname.split("/")[location.pathname.split("/").length - 2]


    let [formValue, setFormValue] = useRecoilState(formState)

    const handleClose = () => {
        setModalOpen(false);
    };

    const [employmentStatus] = useInput({
        data: null,
        status: false,
        initial: null
    });


    const [modalOpen, setModalOpen] = useState(false);

    function getMonthDayDiff(date1, date2) {
        const diff = Math.abs(date1 - date2);
        const diffDate = new Date(diff);
        const years = Math.floor(diffDate / (1000 * 60 * 60 * 24 * 365));
        diffDate.setFullYear(diffDate.getFullYear() - years);
        const months = diffDate.getUTCMonth();
        const days = diffDate.getUTCDate() - 1;

        return { months, days, years };
    }

    function getMonthDayDiffNew(date1, date2) {


        const firstYear = date1.getUTCFullYear();
        const secondYear = date2.getUTCFullYear();



        const diffInMilliseconds = Math.abs(date1 - date2);
        const diffDate = new Date(diffInMilliseconds);
        const years = Math.floor(diffDate / (1000 * 60 * 60 * 24 * 365));
        diffDate.setFullYear(diffDate.getFullYear() - years);

        const months = diffDate.getUTCMonth();
        const days = diffDate.getUTCDate() - 1;
        return { years, months, days };
    }



    let [employed, setEmployed] = useState(
        partnerInfoPath == "partner-info"
            ?
            _.cloneDeep(formValue?.partnerInfo?.page?.employment?.subPage?.employed)
            :
            _.cloneDeep(formValue?.borrowerInfo?.page?.employment?.subPage?.employed)
    )

    let [secondary, setSecondary] = useState(
        partnerInfoPath == "partner-info"
            ?
            _.cloneDeep(formValue?.partnerInfo?.page?.employment?.subPage?.secondary)
            :
            _.cloneDeep(formValue?.borrowerInfo?.page?.employment?.subPage?.secondary)
    )

    let [retired, setRetired] = useState(
        partnerInfoPath == "partner-info"
            ?
            _.cloneDeep(formValue?.partnerInfo?.page?.employment?.subPage?.retired)
            :
            _.cloneDeep(formValue?.borrowerInfo?.page?.employment?.subPage?.retired)
    )

    let [selfEmployed, setSelfEmployed] = useState(
        partnerInfoPath == "partner-info"
            ?
            _.cloneDeep(formValue?.partnerInfo?.page?.employment?.subPage?.selfEmployed)
            :
            _.cloneDeep(formValue?.borrowerInfo?.page?.employment?.subPage?.selfEmployed)
    )

    let [isSideIncomeAvailable, setIsSideIncomeAvailable] = useState(
        partnerInfoPath == "partner-info"
            ?
            _.cloneDeep(formValue?.partnerInfo?.page?.employment?.subPage?.isSideIncomeAvailable)
            :
            _.cloneDeep(formValue?.borrowerInfo?.page?.employment?.subPage?.isSideIncomeAvailable)
    )

    let [sideIncome, setSideIncome] = useState(
        partnerInfoPath == "partner-info"
            ?
            _.cloneDeep(formValue?.partnerInfo?.page?.employment?.subPage?.sideIncome)
            :
            _.cloneDeep(formValue?.borrowerInfo?.page?.employment?.subPage?.sideIncome)
    )


    const empty = (data) => {
        return data.length == 0;
    }
    let [customError, setCustomerror] = useState({})

    const validation = (data) => {
        if (empty(data.retired)) {
            if (empty(data.employed) && empty(data.selfEmployed)) {
                setCustomerror({
                    ...customError,
                    employed: {
                        'message': "Employed details need to be added"
                    },
                    selfEmployed: {
                        'message': "Self Employed details need to be added"
                    },
                })
                return false
            } else {

                let monthsCountEmployed = data?.employed.map((item) => {
                    return getMonthsBetweenDates(item.startDate, item.endDate, item.isStillWorking)
                })
                monthsCountEmployed = monthsCountEmployed.reduce((acc, curr) => acc + curr, 0)


                let monthsCountSelf = 0;
                if (!empty(data.selfEmployed)) {
                    monthsCountSelf = data?.selfEmployed.map((item) => {
                        return getMonthsBetweenDates(item.startDate, item.endDate, item.isStillWorking)
                    })
                    monthsCountSelf = monthsCountSelf.reduce((acc, curr) => acc + curr, 0)
                }

                let totalMonth = (monthsCountEmployed + monthsCountSelf)


                if (totalMonth < 24) {
                    if (data?.employed?.length != 0) {
                        setCustomerror({
                            ...customError,
                            employed: {
                                'message': "Your profession is less than 2 year, Please add more"
                            },
                            selfEmployed: null
                        })
                        return false
                    }
                    if (data?.selfEmployed?.length != 0) {
                        setCustomerror({
                            ...customError,
                            employed: null,
                            selfEmployed: {
                                'message': "Your profession is less than 2 year, Please add more"
                            },
                        })
                        return false
                    }
                    return false
                } else {
                    return true
                }

            }
        } else {
            return true
        }

    }

    const onSubmit = async (data, event) => {

        if (event.target.id == "main") {
            if (!validation(data)) {
                return
            }
            const apiCallData = {
                page: {
                    subPage: {
                        employed: data.employed,
                        secondary: data.secondary,
                        retired: data.retired,

                        selfEmployed: data.selfEmployed,

                        sideIncome: {
                            avgMonthlyComission: data.avgMonthlyComission,
                            avgMonthlyIncome: data.avgMonthlyIncome,
                            avgMonthlyOvertime: data.avgMonthlyOvertime,
                            militaryEntitlement: data.militaryEntitlement

                        }

                    },
                    isComplete: true
                },
                formId: formValue.id,
                isComplete: true
            }

            let updateState, path

            if (partnerInfoPath == "partner-info") {
                path = API.partnerInfoEmployment
                updateState = {
                    ...formValue,
                    partnerInfo: {
                        ...formValue.partnerInfo,
                        page: {
                            ...formValue.partnerInfo.page,
                            employment: apiCallData.page
                        }
                    }

                }

            } else {
                path = API.borrowerInfoEmployment
                updateState = {
                    ...formValue,
                    borrowerInfo: {
                        ...formValue.borrowerInfo,
                        page: {
                            ...formValue.borrowerInfo.page,
                            employment: apiCallData.page
                        }
                    }

                }

            }



            const employmentInfoUpdateInRecoil = () => {
                setFormValue(updateState)

                route(location.pathname.replace(/[^/]*$/, 'additional-income'));

            }

            await postApiCallDynamicWithOutReturn(
                {
                    data: apiCallData,
                    path: path,
                    header: UPDATE,
                    refresh: employmentInfoUpdateInRecoil
                }


            )

        }



    }



    const { setValue, control, handleSubmit, formState: { errors } } = useForm({
        defaultValues: {
            employed: employed,
            secondary: secondary,
            selfEmployed: selfEmployed,
            retired: retired,

            avgMonthlyIncome: sideIncome?.avgMonthlyIncome,
            avgMonthlyOvertime: sideIncome?.avgMonthlyOvertime,
            militaryEntitlement: sideIncome?.militaryEntitlement,
            avgMonthlyComission: sideIncome?.avgMonthlyComission,
        }

    })

    let employmentDates = []

    employed?.forEach((item) => {
        !item.endDate ? employmentDates.push({ date: item.startDate, name: item.businessName }) : employmentDates.push({ date: item.endDate, name: item.businessName })
    })
    selfEmployed.forEach((item) => {
        !item.endDate ? employmentDates.push({ date: item.startDate, name: item.businessName }) : employmentDates.push({ date: item.endDate, name: item.businessName })
    })

    const allEmployment = employed.concat(selfEmployed);
    const sortedEmployed = allEmployment.sort((a, b) => a.startDate - b.startDate);

    let days = 0;


    const getGapTextMultiple = () => {
        let resultArray = []

        for (let i = 0; i < sortedEmployed.length - 1; i++) {


            const startDateCurrent = new Date(sortedEmployed[i].startDate);
            const endDateCurrent = sortedEmployed[i].endDate ? new Date(sortedEmployed[i].endDate) : null;


            const startDateNext = new Date(sortedEmployed[i + 1].startDate);
            const endDateNext = new Date(sortedEmployed[i + 1].endDate);


            if (endDateCurrent == null || endDateCurrent == undefined || Number.isNaN(Date.parse(endDateCurrent))) {

                const { months, days, years } = getMonthDayDiffNew(startDateNext, startDateCurrent);
                if (months >= 1 || days >= 1 || years >= 1) {
                    resultArray.push({
                        name: sortedEmployed[i].businessName + " and " + sortedEmployed[i + 1].businessName,
                        months,
                        years,
                        days
                    });

                }

            } else {

                const { months, days, years } = getMonthDayDiffNew(startDateNext, endDateCurrent);
                if (months >= 1 || days >= 1 || years >= 1) {
                    resultArray.push({
                        name: sortedEmployed[i].businessName + " and " + sortedEmployed[i + 1].businessName,
                        months,
                        years,
                        days
                    });

                }

            }

        }
        let days = 0
        let months = 0
        let years = 0
        let names = []

        resultArray.forEach((item) => {
            years += item.years;
            months += item.months;
            days += item.days;
            names.push(item.name);


        });


        let mssg = "The gap between ";

        resultArray.forEach((item) => {
            let yearsStr = item.years === 1 ? "year" : "years";
            let monthsStr = item.months === 1 ? "month" : "months";
            let daysStr = item.days === 1 ? "day" : "days";

            mssg += `${item.name} is `;


            if (item.years) {
                mssg += `${item.years} ${yearsStr} `;
            }


            if (item.months) {
                mssg += `${item.months} ${monthsStr} `;
            }


            if (item.days) {
                mssg += `${item.days} ${daysStr}, `;
            } else {
                mssg += `, `;
            }

            mssg += "\n";
        });

        function formatDuration(years, months, days) {
            let message = "";


            if (years) {
                let yearsStr = years === 1 ? "year" : "years";
                message += `${years} ${yearsStr} `;
            }


            if (months) {
                let monthsStr = months === 1 ? "month" : "months";
                message += `${months} ${monthsStr} `;
            }


            if (days) {
                let daysStr = days === 1 ? "day" : "days";
                message += `${days} ${daysStr}.`;
            } else {
                message += `.`;
            }

            return message;
        }

        mssg += `\nTotal gap between employments is ${formatDuration(years, months, days)}\n`;
        console.log("Error message: " + mssg);

        const totalDays = (years * 365) + (months * 30) + days;

        return {
            error: mssg,
            isError: (totalDays > 30)
        }
    }


    employmentDates.sort((a, b) => a.date - b.date);

    const getGapText = () => {
        let resultArray = []
        for (let i = 0; i < employmentDates.length - 1; i++) {
            const currentDate = new Date(employmentDates[i].date);
            const nextDate = new Date(employmentDates[i + 1].date);
            const { months, days, years } = getMonthDayDiff(currentDate, nextDate);

            if (months >= 1) {
                resultArray.push({
                    name: employmentDates[i].name,
                    months,
                    years,
                    days
                });
                if (employmentDates.indexOf(employmentDates[i]) === employmentDates.length - 2) {
                    resultArray.push({
                        name: employmentDates[i + 1].name,
                        months: 0,
                        years: 0,
                        days: 0
                    })
                }
            }
        }
        let days = 0
        let months = 0
        let years = 0
        let names = []

        resultArray.forEach((item) => {
            years += item.years
            months += item.months
            days += item.days
            names.push(item.name)
        })

        const totalDays = (years * 365) + (months * 30) + days;
        return {
            error: `The gap between ${names.join(', ')} employer is  ${years ? `${years} year(s) ` : ``}  ${months ? `${months} month(s) ` : ``} ${days ? `${months ? `and ${days} day(s).` : `${days} day(s).`}` : '.'}`,
            isError: (totalDays > 90)
        }
    }



    const errorText = getGapTextMultiple()
    return (
        <>
            <form key={'main'} id='main' onSubmit={handleSubmit(onSubmit)} >
                <Stack direction={'column'} justifyContent={'space-between'} py={2} px={4} height={'inherit'} overflow={'auto'}>
                    <Box>
                        <Breadcrumbs aria-label="breadcrumb"
                            separator={<NavigateNext fontSize="small" />}
                        >
                            <MLink fontWeight={700} underline="hover" color="inherit" href="#">
                                {`${partnerInfoPath == "partner-info" ? "Co-Borrower" : "Borrower"} Info`}
                            </MLink>
                            <Typography color="primary.main" fontWeight={700}>Employment</Typography>
                        </Breadcrumbs>
                        {!!errorText.isError &&
                            <Typography color={'#D94166'} fontSize={'16px'} lineHeight={'150%'} mt={1}>
                                {errorText.error}
                            </Typography>}
                        <Box maxWidth={{ xs: '100%', md: '100%' }} mt={2}>

                            <Employed setValue={setValue} employedList={employed} setEmployedList={setEmployed} />

                            {(customError?.employed && (
                                <FormHelperText sx={{ fontSize: 15 }} error>
                                    {customError.employed.message}
                                </FormHelperText>
                            ))}
                            <SelfEmployed setValue={setValue} employedList={selfEmployed} setEmployedList={setSelfEmployed} />


                            {(customError?.selfEmployed && (
                                <FormHelperText sx={{ fontSize: 15 }} error>
                                    {customError.selfEmployed.message}
                                </FormHelperText>
                            ))}


                            <SecondaryEmployment setValue={setValue} employedList={secondary} setEmployedList={setSecondary} />
                            <Retired setValue={setValue} retiredList={retired} setRetiredList={setRetired} />


                        </Box>
                    </Box>
                    <Box maxWidth={{ xs: '100%', md: '50%' }} mt={2}>
                        <Stack direction={'row'} justifyContent={'space-between'}>
                            <Link to={'../dependent'}>
                                <Button startIcon={<ArrowBackIos />}>
                                    Previous
                                </Button>
                            </Link>
                            <Button form='main' type='submit' endIcon={<ArrowForwardIos />} >
                                Save and Next
                            </Button>
                        </Stack>
                    </Box>

                </Stack>
            </form>

            <DialogCommonHooks
                show={modalOpen}
                handleClose={handleClose}
                title={`Previous Employment`}
                body={
                    <>
                        <Box width={'100%'} my={2} >
                            <Typography>
                                {`We need minimum 2 years of employment history, Please enter your previous employment.`}
                            </Typography>
                            <Stack direction={'row-reverse'} gap={2}>
                                <Button type='button' onClick={() => { employmentStatus.saveValue("previous-employments") }} variant='contained'>Okay</Button>
                                <Button variant='text' onClick={handleClose}>Cancel</Button>
                            </Stack>
                        </Box>
                    </>
                }
            />
        </>
    )
}
