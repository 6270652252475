import React, { Component } from "react";
import IdleTimer from "react-idle-timer";
import { toastNotify } from "./alert-msg";


// Idle Time Counter 
export default class IdelTimerContainer extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = null;
    this.handleOnAction = this.handleOnAction.bind(this);
    this.handleOnActive = this.handleOnActive.bind(this);
    this.handleOnIdle = this.handleOnIdle.bind(this);
  }

  render() {
    return (
      <div>
        <IdleTimer
          ref={(ref) => {
            this.idleTimer = ref;
          }}

          // if user is idel then it will  call handleOnIdle()
          timeout={1000 * 60 * process.env.REACT_APP_SESSION_TIMEOUT}
          onActive={this.handleOnActive}
          onIdle={this.handleOnIdle}
          onAction={this.handleOnAction}
          debounce={250}
        />
        {/* your app here */}
      </div>
    );
  }


  // when user is active it insert a time in the session
  handleOnAction(event) {
    let time = new Date();
    localStorage.setItem("time", time);
  }

  handleOnActive(event) {
    //
  }



  //  if user is idle then this function is called when timeout is zero
  handleOnIdle(event) {
    let time = new Date();

    if (
      Math.abs(time - new Date(localStorage.getItem("time"))) / 60000 >= process.env.REACT_APP_SESSION_TIMEOUT
    ) {
      if (this.props.props.isAuthenticated) {
        toastNotify("error", "Session Timout");
        console.log("session time out triggered");

        this.props.props.handleLogout();
      }
    } else {
      this.idleTimer.reset();
    }
  }
}
