import { EditOutlined, ExpandMore } from '@mui/icons-material'
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import ReviewSection from './review-section'
import { useRecoilState, useRecoilValue } from 'recoil'
import { formState, profileState } from '../../../../../recoil/profile-atom'
import { postApiCallDynamicWithOutReturn } from '../../../../../services/api-service'
import { API } from '../../../../../services/api-config'
import { NEW_KEYS, ROLE_LIST, UPDATE } from '../../../../../constants/constatnt-variable'
import UploadDocumentCard from '../documents/upload-document-card'
import { useForm } from 'react-hook-form'
import { removeKeys } from '../../../../../helpers/common_fuctions/remove-keys'

export default function LoanReview() {


    let [formValue, setFormValue] = useRecoilState(formState)
    let profileInfo = useRecoilValue(profileState);
    const location = useLocation();
    const route = useNavigate();

    console.log(" Form value " + JSON.stringify(formValue, null, 2))


    let {
        getStarted: {
            page: {
                primaryBorrower: primaryBorrowerDetails,
                primaryBorrowerPartner: primaryBorrowerPartnerDetails,
                partner: partnerDetails,
                coBorrower: coBorrowerDetails,
                coborrowersPartner: coBorrowerPartnerDetails,
                hasPartner: hasPartnerDetails
            }
        },
        loanDetails: {
            page: loanDetailsReview
        },
        borrowerInfo: {
            page: {
                personal: {
                    subPage: personalDetails,
                },
                contact: {
                    subPage: {
                        currentAddress: currentAddressDetails,
                        mailingAddress: mailingAddressDetails,
                        additionalAddresses: additionalAddressesDetails
                    }
                },
                demographics: {
                    subPage: demographicsDetails
                },
                declarations: {
                    subPage: declarationsDetails
                },
                dependent: {
                    subPage: dependentDetails
                },
                employment: {
                    subPage: {
                        employed: employedDetails,
                        retired: retiredDetails,
                        secondary: secondaryDetails,
                        selfEmployed: selfEmployedDetails,
                    }
                },
                additionalIncome: {
                    subPage: {
                        additionalIncome: additionalIncomeDetails
                    }
                },
                militaryServices: {
                    subPage: militaryServicesDetails
                },
            }
        },

        assetsCredits: {

            page: {

                assetCredit: assetCreditDetails,
                assetOther: assetOtherDetails,
                creditOther: creditOtherDetails
            }
        },
        giftGrants: {

            page: giftDetails
        },
        realEstate: {
            page: realEstateDetails
        },
        liabilities: {
            page: {
                expense: expenseDetails,
                mortgage: mortgageDetails,
                nonMortgage: nonMortgageDetails,
            }
        },
        documents: {
            page: {
                guidedDocument: {
                    subPage: document
                }
            }
        },
        documents: {
            page: {
                additionalDocument: {
                    subPage: additionalDocumentReview
                }
            }
        },
        borrowerType: borrowerType
    } = formValue


    let PARTNER_INFO = []

    console.log(" borrowerType " + borrowerType)

    let hasPartnerBorrower = null, hasPartnerCoBorrower = null
    if (borrowerType === 'Primary')
        hasPartnerBorrower = hasPartnerDetails
    else
        hasPartnerCoBorrower = hasPartnerDetails

    const set_partner_info = () => {
        if (formValue.hasPartner) {
            let { partnerInfo: {
                page: {
                    personal: {
                        subPage: personalDetailsPartner,
                    },
                    contact: {
                        subPage: {
                            currentAddress: currentAddressDetailsPartner,
                            mailingAddress: mailingAddressDetailsPartner,
                            additionalAddresses: additionalAddressesDetailsPartner
                        }
                    },
                    demographics: {
                        subPage: demographicsDetailsPartner
                    },
                    declarations: {
                        subPage: declarationsDetailsPartner
                    },
                    dependent: {
                        subPage: dependentDetailsPartner
                    },
                    employment: {
                        subPage: {
                            employed: employedDetailsPartner,
                            retired: retiredDetailsPartner,
                            secondary: secondaryDetailsPartner,
                            selfEmployed: selfEmployedDetailsPartner,
                        }
                    },
                    additionalIncome: {
                        subPage: {
                            additionalIncome: additionalIncomeDetailsPartner
                        }
                    },
                    militaryServices: {
                        subPage: militaryServicesDetailsPartner
                    },
                }
            }, } = formValue

            employedDetailsPartner = employedDetailsPartner.map((e, i) => ({
                ...e, "chidSectionName": `Employed 0${i + 1}`
            }))


            retiredDetailsPartner = retiredDetailsPartner.map((e, i) => ({
                ...e, "chidSectionName": `Retired 0${i + 1}`
            }))


            secondaryDetailsPartner = secondaryDetailsPartner.map((e, i) => ({
                ...e, "chidSectionName": `Secondary 0${i + 1}`
            }))


            selfEmployedDetailsPartner = selfEmployedDetailsPartner.map((e, i) => ({
                ...e, "chidSectionName": `Self Employed 0${i + 1}`
            }))

            additionalIncomeDetailsPartner = additionalIncomeDetailsPartner.map((e, i) => ({
                ...e, "chidSectionName": `Additional Income (0${i + 1})`
            }))

            additionalAddressesDetailsPartner = additionalAddressesDetailsPartner.map((e, i) => ({
                ...e, "chidSectionName": `Additional Address (0${i + 1})`
            }))



            PARTNER_INFO = [
                { "chidSectionName": "Personal", ...personalDetailsPartner },
                { "chidSectionName": "Contact", ...currentAddressDetailsPartner },
                { "chidSectionName": "Mailing Address", ...mailingAddressDetailsPartner },
                { "chidSectionName": "Demographics", ...demographicsDetailsPartner },
                { "chidSectionName": "Declarations", ...declarationsDetailsPartner },
                { "chidSectionName": "Dependent", ...dependentDetailsPartner },

                { "chidSectionName": "Military Services", ...militaryServicesDetailsPartner },
            ].concat(additionalAddressesDetailsPartner, employedDetailsPartner, retiredDetailsPartner, secondaryDetailsPartner, selfEmployedDetailsPartner, additionalIncomeDetailsPartner)
        }
    }

    set_partner_info()

    function capitalizeEachWord(str) {
        if (str != null) {
            return str.toLowerCase().replace(/(^|\s)\S/g, function (match) {
                return match.toUpperCase();
            });
        }
        else return null;
    }



    coBorrowerDetails = coBorrowerDetails.map((e, i) => {

        const fullName = e.firstName && e.lastName ? `${e.firstName} ${e.lastName}` : '';
        const capitalizedFullName = capitalizeEachWord(fullName);
        const updatedName = `CA (${capitalizedFullName})`;
        return {
            ...e,
            chidSectionName: updatedName
        };
    });



    const updatedPrimaryBorrowerDetails = { ...primaryBorrowerDetails };
    if (primaryBorrowerDetails && primaryBorrowerDetails.firstName && primaryBorrowerDetails.lastName) {
        updatedPrimaryBorrowerDetails.chidSectionName = `PB (${primaryBorrowerDetails.firstName} ${primaryBorrowerDetails.lastName})`;
    }

    let updatedPrimaryBorrowerPartnerDetails = null, primaryBorrowerPartnerSectionName = null
    if (hasPartnerBorrower === 'Yes' && borrowerType === 'Primary') {
        updatedPrimaryBorrowerPartnerDetails = { ...primaryBorrowerPartnerDetails };
        if (primaryBorrowerPartnerDetails && primaryBorrowerPartnerDetails.firstName && primaryBorrowerPartnerDetails.lastName) {
            updatedPrimaryBorrowerPartnerDetails.chidSectionName = `PB-CB (${primaryBorrowerPartnerDetails.firstName} ${primaryBorrowerPartnerDetails.lastName})`;
            primaryBorrowerPartnerSectionName = capitalizeEachWord(`${updatedPrimaryBorrowerPartnerDetails.firstName || ''} ${updatedPrimaryBorrowerPartnerDetails.lastName || ''}`);

        }
    }

    if (borrowerType === 'Co-borrower') {
        updatedPrimaryBorrowerPartnerDetails = { ...primaryBorrowerPartnerDetails };
        if (primaryBorrowerPartnerDetails && primaryBorrowerPartnerDetails.firstName && primaryBorrowerPartnerDetails.lastName) {
            updatedPrimaryBorrowerPartnerDetails.chidSectionName = `PB-CB (${primaryBorrowerPartnerDetails.firstName} ${primaryBorrowerPartnerDetails.lastName})`;
            primaryBorrowerPartnerSectionName = capitalizeEachWord(`${updatedPrimaryBorrowerPartnerDetails.firstName || ''} ${updatedPrimaryBorrowerPartnerDetails.lastName || ''}`);

        }
    }


    let updatedPartnerDetails = null, partnerSectionName = null
    if (hasPartnerCoBorrower === 'Yes' && borrowerType === 'Co-borrower') {
        updatedPartnerDetails = { ...partnerDetails };
        if (partnerDetails && partnerDetails.firstName && partnerDetails.lastName) {
            updatedPartnerDetails.chidSectionName = `CA-CB (${partnerDetails.firstName} ${partnerDetails.lastName})`;
            partnerSectionName = capitalizeEachWord(`${updatedPartnerDetails.firstName || ''} ${updatedPartnerDetails.lastName || ''}`);
        }
    }


    if (borrowerType === 'Primary') {

        updatedPartnerDetails = coBorrowerPartnerDetails[0]
        updatedPartnerDetails = { ...updatedPartnerDetails };
        if (updatedPartnerDetails && updatedPartnerDetails.firstName && updatedPartnerDetails.lastName) {
            updatedPartnerDetails.chidSectionName = `CA-CB (${updatedPartnerDetails.firstName} ${updatedPartnerDetails.lastName})`;
            partnerSectionName = capitalizeEachWord(`${updatedPartnerDetails.firstName || ''} ${updatedPartnerDetails.lastName || ''}`);
        }
    }


    const primaryBorrowerSectionName = capitalizeEachWord(`${updatedPrimaryBorrowerDetails.firstName || ''} ${updatedPrimaryBorrowerDetails.lastName || ''}`);


    const GET_STARTED = [
        { "chidSectionName": primaryBorrowerSectionName, ...updatedPrimaryBorrowerDetails },
        { "chidSectionName": primaryBorrowerPartnerSectionName, ...updatedPrimaryBorrowerPartnerDetails },
        ...coBorrowerDetails,
        { "chidSectionName": partnerSectionName, ...updatedPartnerDetails }
    ];







    const LOAN_DETAILS = [
        { "chidSectionName": "Property Details" },

    ].concat(loanDetailsReview)




    employedDetails = employedDetails.map((e, i) => ({
        ...e, "chidSectionName": `Employed (0${i + 1})`
    }))


    retiredDetails = retiredDetails.map((e, i) => ({
        ...e, "chidSectionName": `Retired (0${i + 1})`
    }))


    secondaryDetails = secondaryDetails.map((e, i) => ({
        ...e, "chidSectionName": `Secondary (0${i + 1})`
    }))


    selfEmployedDetails = selfEmployedDetails.map((e, i) => ({
        ...e, "chidSectionName": `Self Employed (0${i + 1})`
    }))


    additionalIncomeDetails = additionalIncomeDetails.map((e, i) => ({
        ...e, "chidSectionName": `Additional Income (0${i + 1})`
    }))

    additionalAddressesDetails = additionalAddressesDetails.map((e, i) => ({
        ...e, "chidSectionName": `Additional Address (0${i + 1})`
    }))

    const BORROWER_INFO = [
        { "chidSectionName": "Personal", ...personalDetails },
        { "chidSectionName": "Contact", ...currentAddressDetails },
        { "chidSectionName": "Mailing Address", ...mailingAddressDetails },
        { "chidSectionName": "Demographics", ...demographicsDetails },
        { "chidSectionName": "Declarations", ...declarationsDetails },
        { "chidSectionName": "Dependent", ...dependentDetails }

    ].concat(additionalAddressesDetails, employedDetails, retiredDetails, secondaryDetails, selfEmployedDetails, additionalIncomeDetails, { "chidSectionName": "Military Services", ...militaryServicesDetails })




    assetCreditDetails = assetCreditDetails.map((e, i) => ({
        ...e, "chidSectionName": `Assets Info (0${i + 1})`
    }))

    assetOtherDetails = assetOtherDetails.map((e, i) => ({
        ...e, "chidSectionName": `Other Assets (0${i + 1})`
    }))

    creditOtherDetails = creditOtherDetails.map((e, i) => ({
        ...e, "chidSectionName": `Other Credits (0${i + 1})`
    }))


    const ASSETS_CREDITS = assetCreditDetails.concat(assetOtherDetails).concat(creditOtherDetails)



    realEstateDetails = realEstateDetails.map((e, i) => ({
        ...e, "chidSectionName": `Real Estate Info (0${i + 1})`
    }))
    const REAL_ESTATE = realEstateDetails

    giftDetails = giftDetails.map((e, i) => ({
        ...e, "chidSectionName": `Gift & Grants (0${i + 1})`
    }))
    const GIFT_GRANTS = giftDetails



    mortgageDetails = mortgageDetails.map((e, i) => ({
        ...e, "chidSectionName": `Mortgate Liability 0${i + 1}`
    }))


    nonMortgageDetails = nonMortgageDetails.map((e, i) => ({
        ...e, "chidSectionName": `Non Mortgate Liability 0${i + 1}`
    }))


    expenseDetails = expenseDetails.map((e, i) => ({
        ...e, "chidSectionName": `Expenses 0${i + 1}`
    }))
    const LIABILITIES = mortgageDetails.concat(nonMortgageDetails, expenseDetails)


    const ADDITIONAL_DOCUMENT = additionalDocumentReview


    const handleSubmit = async () => {
        const apiCallData = {
            page: {},
            formId: formValue?.id,
            isComplete: true
        }

        const updateState = () => {
            setFormValue({
                ...formValue,
                review: {
                    ...formValue.review,
                    page: {},
                    isComplete: true,
                }
            })
            route(location.pathname.replace(/[^/]*$/, 'submit'));
        }

        await postApiCallDynamicWithOutReturn(
            {
                data: apiCallData,
                path: API.review,
                header: UPDATE,
                refresh: updateState
            }

        )


    }




    const { control } = useForm()
    return (
        <>
            <Stack direction={'column'} justifyContent={'space-between'} py={2} px={4} height={'inherit'} overflow={'auto'}>
                <Box>
                    <Typography variant='h2' fontSize={'28px'} mb={2} color={'primary'}>{`Review my application`}</Typography>

                    <ReviewSection
                        sectionInfo={GET_STARTED}
                        sectionName={'Get Started!'}
                        // chidSectionName={'Primary Borrower'}
                        hasPartner={formValue?.hasPartner ? '' : 'This loan has no partner.'}
                        editUrl={'getting-started'}
                        isLocked={formValue?.isLocked}
                    />
                    <ReviewSection
                        sectionInfo={LOAN_DETAILS}
                        sectionName={'Loan Details'}
                        //chidSectionName={'Property Details'}
                        editUrl={'loan-details'}
                        isLocked={formValue?.isLocked}
                    />



                    <ReviewSection
                        sectionInfo={BORROWER_INFO}
                        sectionName={'Borrower Info'}
                        hasPartner={formValue?.hasPartner ? '' : 'This loan has no partner.'}
                        isLocked={formValue?.isLocked}
                    />
                    {formValue?.hasPartner ?
                        <ReviewSection
                            sectionInfo={PARTNER_INFO}
                            sectionName={'Co-Borrower'}
                            editUrl={'partner-info/personal-information'}
                            isLocked={formValue?.isLocked}
                        /> : null
                    }

                    <ReviewSection
                        sectionInfo={ASSETS_CREDITS}
                        sectionName={'Assets & Credits'}
                        editUrl={'assets-credits'}
                        isLocked={formValue?.isLocked}
                    />
                    <ReviewSection
                        sectionInfo={GIFT_GRANTS}
                        sectionName={'Gift & Grants'}
                        editUrl={'gift-grants'}
                        isLocked={formValue?.isLocked}
                    />
                    <ReviewSection
                        sectionInfo={REAL_ESTATE}
                        sectionName={'Real Estate'}
                        editUrl={'real-estate'}
                        isLocked={formValue?.isLocked}
                    />

                    <ReviewSection
                        sectionInfo={LIABILITIES}
                        sectionName={'Liabilities'}
                        editUrl={'liabilities'}
                        isLocked={formValue?.isLocked}
                    />
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography variant='h3' fontSize={'20px'}>{'Document'}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Stack display={ADDITIONAL_DOCUMENT.length == 0 ? 'none' : ''} direction={'row'} justifyContent={'space-between'} mb={1}>
                                <Typography variant='h5' color='primary'>{`Guided Documents`}</Typography>
                                <Button variant='outlined' disabled={profileInfo.role == ROLE_LIST.borrower ? formValue?.isLocked : false} onClick={() => route(location.pathname.replace(/[^/]*$/, 'documents/guided-document'))} startIcon={<EditOutlined />}>{`Edit`}</Button>
                            </Stack>
                            {
                                document.map((item, index) => (
                                    <>
                                        <UploadDocumentCard
                                            key={item.title}
                                            title={item.title}
                                            control={control}
                                            data={item.documents}
                                            description={item.description}
                                            isReview={true}
                                        />


                                    </>
                                ))
                            }
                            <Stack display={ADDITIONAL_DOCUMENT.length == 0 ? 'none' : ''} direction={'row'} justifyContent={'space-between'} mb={1}>
                                <Typography variant='h5' color='primary'>{`Additional Document`}</Typography>
                                <Button variant='outlined' disabled={profileInfo.role == ROLE_LIST.borrower ? formValue?.isLocked : false} onClick={() => route(location.pathname.replace(/[^/]*$/, 'documents/additional-document'))} startIcon={<EditOutlined />}>{`Edit`}</Button>
                            </Stack>
                            {
                                ADDITIONAL_DOCUMENT.map((data, i) => (
                                    <>
                                        <Box key={i}>
                                            <Grid container>

                                                {
                                                    Object.keys(removeKeys(data)).map(
                                                        (key, i) => {
                                                            return (
                                                                <Grid key={i} item xs={12} sm={6} md={4} xl={3} p={2}>
                                                                    <Typography variant='body1' color={'accent.A5'}>
                                                                        {(NEW_KEYS[key] || key)}
                                                                    </Typography>
                                                                    <Typography variant='body1'>
                                                                        {
                                                                            data[key]
                                                                        }

                                                                    </Typography>
                                                                </Grid>
                                                            );
                                                        }
                                                    )

                                                }
                                            </Grid>
                                        </Box>
                                    </>
                                ))
                            }
                        </AccordionDetails>

                    </Accordion>

                </Box>

                <Box width={'100%'} mt={2}>
                    <Stack direction={'row-reverse'}>

                        <Button disabled={profileInfo.role == ROLE_LIST.borrower ? formValue?.isLocked : false} variant='contained' onClick={handleSubmit} >
                            Proceed
                        </Button>
                    </Stack>
                </Box>
            </Stack>
        </>
    )
}
