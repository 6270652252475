import {
  Button,
  CardActions,
  CardContent,
  Collapse,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  Radio,
  RadioGroup,
  styled,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import MainLoyOut from "../../../../components/main-layout";
import CustomInput from "../../../../helpers/custom-input";
import CustomInputMultiLine from "../../../../helpers/custom-input-multiline";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Controller, useForm, useWatch } from "react-hook-form";
import { DateToTimestapmFormater, TimeStampToMonthDateYearForInput } from "../../../../helpers/time-formater";
import RadioButtonGroup from "../../../../helpers/radio-button-group";
import { PURPOSE_LIST, LOAN_TYPE_LIST, UPDATE } from "../../../../constants/constatnt-variable";
import { API } from "../../../../services/api-config";
import { postApiCallDynamicWithOutReturn } from "../../../../services/api-service";
import { useLocation, useNavigate } from "react-router-dom";
import { downloadReportFromServer, getFileUrl } from "../../../../helpers/file-upload-download";
import { QuoteFormValidation } from "../../../../constants/validation-rules";
import { DialogCommonHooks, PreviewPDFModal } from "../../../../helpers/modal/modal";
import FilePreview from "../file-preview";
import { useRecoilState } from "recoil";
import { globalSpinner } from "../../../../recoil/profile-atom";
import { LOAN_TERM_TYPE } from "../../../../constants/constatnt-variable";
import CustomDropDown from "../../../../helpers/dropdown/custom-dropdown";
import { formatNumericValue, parseNumericValue } from '../../../../helpers/numeric-format'
import { Tooltip } from '@mui/material';
import { AttachMoney } from '@mui/icons-material'
import CustomTooltip from "../../../../helpers/custom-tooltip";


const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function QuoteForm({ leadData, profileData }) {



  const [expanded, setExpanded] = useState(false);


  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const router = useNavigate();
  const location = useLocation();
  const leadId = location.pathname.split("/")[location.pathname.split("/").length - 1];
  const [modalOpen, setModalOpen] = useState(false);
  let [, setSpinner] = useRecoilState(globalSpinner);


  const handleClose = () => {
    router("/broker/leads")
  }




  const onSubmit = async data => {
    setSpinner(true)
    let apiCallData = {
      id: leadId,
      leadInfo: {
        firstName: data?.firstName,
        lastName: data?.lastName,
        email: data?.email,
        dateOfBirth: DateToTimestapmFormater(data?.dateOfBirth),
        phone: data?.phone,
        ssn: data?.ssn,
        monthlyIncome: data?.monthlyIncome,
        totalLiabilities: data?.totalLiabilities,
      },
      leadForm: {
        interestRate: data?.interestRate,
        points: data?.points,
        pmiAmountPerMonth: data?.pmiAmountPerMonth,
        mmpAmountPerMonth: data?.mmpAmountPerMonth,
        salePrice: data?.salePrice,
        loanAmount: data?.loanAmount,
        closingCostWorksheet: {
          sheetFirstName: data?.sheetFirstName,
          sheetLastName: data?.sheetLastName,
          sheetEmail: data?.sheetEmail,
          sheetNMLS: data?.sheetNMLS,
          sheetPhone: data?.sheetPhone,
          sheetPhoneAlt: data?.sheetPhoneAlt,
          sheetLoanType: data?.sheetLoanType,
          sheetLoanPurpose: data?.sheetLoanPurpose,
          sheetZip: data?.sheetZip,
          sheetPropertyAddress: data?.sheetPropertyAddress,
          sheetSalePrice: data?.sheetSalePrice,
          sheetIssueDate: data?.sheetIssueDate,
          sheetLoanAmount: data?.sheetLoanAmount,
          sheetLoanTerm: data?.sheetLoanTerm,
          sheetInterestRate: data?.sheetInterestRate,
          sheetAnnualPercentageRate: data?.sheetAnnualPercentageRate,
          sheetLoanToValue: data?.sheetLoanToValue,
          sheetPrincipalAndInterest: data?.sheetPrincipalAndInterest,
          sheetOtherFinancing: data?.sheetOtherFinancing,
          sheetOthers: data?.sheetOthers,
          sheetHazardInsurance: data?.sheetHazardInsurance,
          sheetPropertyTaxes: data?.sheetPropertyTaxes,
          sheetMortgageInsurance: data?.sheetMortgageInsurance,
          sheetHOADues: data?.sheetHOADues,

          sheetFundDownPayment: data?.sheetFundDownPayment,
          sheetFundClosingCosts: data?.sheetFundClosingCosts,
          sheetFundReserves: data?.sheetFundReserves,
          sheetFundFundingFee: data?.sheetFundFundingFee,

          sheetCreditsEarnestMoney: data?.sheetCreditsEarnestMoney,
          sheetCreditsSellerCredit: data?.sheetCreditsSellerCredit,
          sheetCreditsLenderCredit: data?.sheetCreditsLenderCredit,
          sheetCreditsSubordinateFinancing: data?.sheetCreditsSubordinateFinancing,

          sheetOriginationAdministrationFee: data?.sheetOriginationAdministrationFee,
          sheetOriginationDiscountPoints: data?.sheetOriginationDiscountPoints,

          sheetLenderAppraisalFee: data?.sheetLenderAppraisalFee,
          sheetLenderCreditReportFee: data?.sheetLenderCreditReportFee,
          sheetLenderFloodCertificationFee: data?.sheetLenderFloodCertificationFee,
          sheetLenderMERSFee: data?.sheetLenderMERSFee,
          sheetLenderNewDebtMonitoring: data?.sheetLenderNewDebtMonitoring,
          sheetLenderTaxServiceFee: data?.sheetLenderTaxServiceFee,

          sheetTitleCountyTransferTax: data?.sheetTitleCountyTransferTax,
          sheetTitleRecordingFee: data?.sheetTitleRecordingFee,
          sheetTitleInsurance: data?.sheetTitleInsurance,
          sheetTitleCourierFee: data?.sheetTitleCourierFee,
          sheetTitleMobileNotaryFee: data?.sheetTitleMobileNotaryFee,
          sheetTitleSettlement: data?.sheetTitleSettlement,

          sheetPrePaidItemsPerDiemInterest: data?.sheetPrePaidItemsPerDiemInterest,
          sheetPrePaidItemsEstimatedReserves: data?.sheetPrePaidItemsEstimatedReserves,

          sheetTransactionSalePrice: data?.sheetTransactionSalePrice,
          sheetTransactionReserves: data?.sheetTransactionReserves,
          sheetTransactionIssueDate: data?.sheetTransactionIssueDate,
          sheetTransactionLoanAmount: data?.sheetTransactionLoanAmount,
          sheetTransactionLoanTerm: data?.sheetTransactionLoanTerm,
          sheetTransactionInterestRate: data?.sheetTransactionInterestRate,
          sheetTransactionAnnualPercentageRate: data?.sheetTransactionAnnualPercentageRate,
          sheetTransactionLoanToValue: data?.sheetTransactionLoanToValue,
          sheetTransactionPrincipalAndInterest: data?.sheetTransactionPrincipalAndInterest,
          sheetTransactionOtherFinancing: data?.sheetTransactionOtherFinancing,
          sheetTransactionOthers: data?.sheetTransactionOthers,
          sheetTransactionHazardInsurance: data?.sheetTransactionHazardInsurance,
          sheetTransactionPropertyTaxes: data?.sheetTransactionPropertyTaxes,
          sheetTransactionMortgageInsurance: data?.sheetTransactionMortgageInsurance,
          sheetTransactionHOADues: data?.sheetTransactionHOADues,
        }
      }
    }
    await postApiCallDynamicWithOutReturn({
      data: apiCallData, refresh: () => { setSpinner(false); setModalOpen(true); }, path: API.lead, header: UPDATE
    })


  }


  const downloadPdf = () => {
    downloadReportFromServer(API.leadDownload, { id: leadId, serviceType: "quote" })

  }

  const handlePdfClose = () => {
    setModalOpen(false)
  }

  const { control, handleSubmit, formState: { errors }, watch, setValue } = useForm({
    defaultValues: {
      firstName: leadData?.leadInfo?.firstName,
      lastName: leadData?.leadInfo?.lastName,
      email: leadData?.leadInfo?.email,
      dateOfBirth: TimeStampToMonthDateYearForInput(leadData?.leadInfo?.dateOfBirth),
      phone: leadData?.leadInfo?.phone,
      ssn: leadData?.leadInfo?.ssn,
      monthlyIncome: leadData?.leadInfo?.monthlyIncome,
      totalLiabilities: leadData?.leadInfo?.totalLiabilities,

      interestRate: leadData?.leadForm?.interestRate,
      points: leadData?.leadForm?.points,
      pmiAmountPerMonth: leadData?.leadForm?.pmiAmountPerMonth,
      mmpAmountPerMonth: leadData?.leadForm?.mmpAmountPerMonth,


      sheetFirstName: leadData?.leadForm?.closingCostWorksheet?.sheetFirstName || profileData?.firstName || "",
      sheetLastName: leadData?.leadForm?.closingCostWorksheet?.sheetLastName || profileData?.lastName || "",
      sheetEmail: leadData?.leadForm?.closingCostWorksheet?.sheetEmail || profileData?.email || "",
      sheetNMLS: leadData?.leadForm?.closingCostWorksheet?.sheetNMLS || profileData?.nmlsId || "",
      sheetPhone: leadData?.leadForm?.closingCostWorksheet?.sheetPhone || profileData?.phone || "",
      sheetPhoneAlt: leadData?.leadForm?.closingCostWorksheet?.sheetPhoneAlt || profileData?.phone || "",

      sheetLoanType: leadData?.leadForm?.closingCostWorksheet?.sheetLoanType || leadData?.leadInfo?.loanType,
      sheetLoanPurpose: leadData?.leadForm?.closingCostWorksheet?.sheetLoanPurpose || leadData?.leadInfo?.loanPurpose,

      sheetZip: leadData?.leadForm?.closingCostWorksheet?.sheetZip || leadData?.leadInfo?.propertyZip,

      sheetPropertyAddress: leadData?.leadForm?.closingCostWorksheet?.sheetPropertyAddress || leadData?.leadInfo?.propertyAddress,

      sheetSalePrice: leadData?.leadForm?.closingCostWorksheet?.sheetSalePrice || leadData?.leadInfo?.propertyValue,
      salePrice: leadData?.leadForm?.closingCostWorksheet?.sheetSalePrice,
      sheetIssueDate: leadData?.leadInfo?.closingCostWorksheet?.sheetIssueDate,

      sheetLoanAmount:

        leadData?.leadInfo?.loanPurpose === 'Purchasing'
          ? leadData?.leadForm?.closingCostWorksheet?.sheetLoanAmount || (parseFloat(leadData?.leadInfo?.propertyValue) - parseFloat(leadData?.leadInfo?.downPaymentFixed))
          : leadData?.leadInfo?.loanPurpose === 'Refinancing'
            ? leadData?.leadInfo?.loanAmount
            : 0,





      sheetLoanTerm: leadData?.leadForm?.closingCostWorksheet?.sheetLoanTerm || leadData?.leadInfo?.loanTerm,

      sheetInterestRate: leadData?.leadForm?.closingCostWorksheet?.sheetInterestRate || leadData?.leadForm?.interestRate,
      sheetAnnualPercentageRate: leadData?.leadForm?.closingCostWorksheet?.sheetAnnualPercentageRate,

      sheetLoanToValue:
        leadData?.leadForm?.closingCostWorksheet?.sheetLoanToValue ||
        (((parseFloat(leadData?.leadInfo?.propertyValue) - (parseFloat(leadData?.leadInfo?.propertyValue) * parseFloat(leadData?.leadInfo?.downPaymentPercentage) / 100)) / parseFloat(leadData?.leadInfo?.propertyValue) * 100)).toFixed(2),

      sheetPrincipalAndInterest: leadData?.leadForm?.closingCostWorksheet?.sheetPrincipalAndInterest,
      sheetOtherFinancing: leadData?.leadForm?.closingCostWorksheet?.sheetOtherFinancing,
      sheetOthers: leadData?.leadForm?.closingCostWorksheet?.sheetOthers,
      sheetHazardInsurance: leadData?.leadForm?.closingCostWorksheet?.sheetHazardInsurance,
      sheetPropertyTaxes: leadData?.leadForm?.closingCostWorksheet?.sheetPropertyTaxes,
      sheetMortgageInsurance: leadData?.leadForm?.closingCostWorksheet?.sheetMortgageInsurance,
      sheetHOADues: leadData?.leadForm?.closingCostWorksheet?.sheetHOADues,

      sheetFundDownPayment:

        leadData?.leadInfo?.loanPurpose === 'Purchasing'
          ? leadData?.leadForm?.closingCostWorksheet?.sheetFundDownPayment || leadData?.leadInfo?.downPaymentPercentage
          : leadData?.leadInfo?.loanPurpose === 'Refinancing'
            ? ((parseFloat(leadData?.leadInfo?.propertyValue) - parseFloat(leadData?.leadInfo?.loanAmount)) * 100 / parseFloat(leadData?.leadInfo?.propertyValue)).toFixed(2)
            : 0,



      sheetFundClosingCosts: leadData?.leadForm?.closingCostWorksheet?.sheetFundClosingCosts,
      sheetFundReserves: leadData?.leadForm?.closingCostWorksheet?.sheetFundReserves,
      sheetFundFundingFee: leadData?.leadForm?.closingCostWorksheet?.sheetFundFundingFee,

      sheetCreditsEarnestMoney: leadData?.leadForm?.closingCostWorksheet?.sheetCreditsEarnestMoney,
      sheetCreditsSellerCredit: leadData?.leadForm?.closingCostWorksheet?.sheetCreditsSellerCredit,
      sheetCreditsLenderCredit: leadData?.leadForm?.closingCostWorksheet?.sheetCreditsLenderCredit,
      sheetCreditsSubordinateFinancing: leadData?.leadForm?.closingCostWorksheet?.sheetCreditsSubordinateFinancing,

      sheetOriginationAdministrationFee: leadData?.leadForm?.closingCostWorksheet?.sheetOriginationAdministrationFee,
      sheetOriginationDiscountPoints: leadData?.leadForm?.closingCostWorksheet?.sheetOriginationDiscountPoints,


      sheetLenderAppraisalFee: leadData?.leadForm?.closingCostWorksheet?.sheetLenderAppraisalFee,
      sheetLenderCreditReportFee: leadData?.leadForm?.closingCostWorksheet?.sheetLenderCreditReportFee,
      sheetLenderFloodCertificationFee: leadData?.leadForm?.closingCostWorksheet?.sheetLenderFloodCertificationFee,
      sheetLenderMERSFee: leadData?.leadForm?.closingCostWorksheet?.sheetLenderMERSFee,
      sheetLenderNewDebtMonitoring: leadData?.leadForm?.closingCostWorksheet?.sheetLenderNewDebtMonitoring,
      sheetLenderTaxServiceFee: leadData?.leadForm?.closingCostWorksheet?.sheetLenderTaxServiceFee,

      sheetTitleCountyTransferTax: leadData?.leadForm?.closingCostWorksheet?.sheetTitleCountyTransferTax,
      sheetTitleRecordingFee: leadData?.leadForm?.closingCostWorksheet?.sheetTitleRecordingFee,
      sheetTitleInsurance: leadData?.leadForm?.closingCostWorksheet?.sheetTitleInsurance,
      sheetTitleCourierFee: leadData?.leadForm?.closingCostWorksheet?.sheetTitleCourierFee,
      sheetTitleMobileNotaryFee: leadData?.leadForm?.closingCostWorksheet?.sheetTitleMobileNotaryFee,
      sheetTitleSettlement: leadData?.leadForm?.closingCostWorksheet?.sheetTitleSettlement,

      sheetPrePaidItemsPerDiemInterest: leadData?.leadForm?.closingCostWorksheet?.sheetPrePaidItemsPerDiemInterest,
      sheetPrePaidItemsEstimatedReserves: leadData?.leadForm?.closingCostWorksheet?.sheetPrePaidItemsEstimatedReserves,



      sheetTransactionSalePrice: leadData?.leadForm?.closingCostWorksheet?.sheetTransactionSalePrice || leadData?.leadInfo?.propertyValue,
      sheetTransactionReserves: leadData?.leadForm?.closingCostWorksheet?.sheetTransactionReserves,
      sheetTransactionIssueDate: leadData?.leadForm?.closingCostWorksheet?.sheetTransactionIssueDate,
      sheetTransactionLoanAmount: leadData?.leadForm?.closingCostWorksheet?.sheetTransactionLoanAmount,
      sheetTransactionLoanTerm: leadData?.leadForm?.closingCostWorksheet?.sheetTransactionLoanTerm,
      sheetTransactionInterestRate: leadData?.leadForm?.closingCostWorksheet?.sheetTransactionInterestRate,
      sheetTransactionAnnualPercentageRate: leadData?.leadForm?.closingCostWorksheet?.sheetTransactionAnnualPercentageRate,
      sheetTransactionLoanToValue: leadData?.leadForm?.closingCostWorksheet?.sheetTransactionLoanToValue,
      sheetTransactionPrincipalAndInterest: leadData?.leadForm?.closingCostWorksheet?.sheetTransactionPrincipalAndInterest,
      sheetTransactionOtherFinancing: leadData?.leadForm?.closingCostWorksheet?.sheetTransactionOtherFinancing,
      sheetTransactionOthers: leadData?.leadForm?.closingCostWorksheet?.sheetTransactionOthers,
      sheetTransactionHazardInsurance: leadData?.leadForm?.closingCostWorksheet?.sheetTransactionHazardInsurance,
      sheetTransactionPropertyTaxes: leadData?.leadForm?.closingCostWorksheet?.sheetTransactionPropertyTaxes,
      sheetTransactionMortgageInsurance: leadData?.leadForm?.closingCostWorksheet?.sheetTransactionMortgageInsurance,
      sheetTransactionHOADues: leadData?.leadForm?.closingCostWorksheet?.sheetTransactionHOADues,

    }
  })



  const { interestRate,

    sheetFundFundingFee,
    sheetOriginationAdministrationFee,
    sheetOriginationDiscountPoints,
    sheetLenderAppraisalFee,
    sheetLenderCreditReportFee,
    sheetLenderFloodCertificationFee,
    sheetLenderMERSFee,
    sheetLenderNewDebtMonitoring,
    sheetLenderTaxServiceFee,
    sheetTitleCountyTransferTax,
    sheetTitleRecordingFee,
    sheetTitleInsurance,
    sheetTitleCourierFee,
    sheetTitleMobileNotaryFee,
    sheetTitleSettlement,
    sheetPrePaidItemsPerDiemInterest,
    sheetPrePaidItemsEstimatedReserves,
    sheetCreditsEarnestMoney,
    sheetCreditsSellerCredit,
    sheetCreditsLenderCredit,
    sheetCreditsSubordinateFinancing,

    sheetSalePrice,
    sheetFundReserves,
    sheetIssueDate,
    sheetLoanAmount,
    sheetLoanTerm,
    sheetInterestRate,
    sheetAnnualPercentageRate,
    sheetLoanToValue,
    sheetPrincipalAndInterest,
    sheetOtherFinancing,
    sheetOthers,
    sheetHazardInsurance,
    sheetPropertyTaxes,
    sheetMortgageInsurance,
    sheetHOADues,

    sheetFundDownPayment,
    points,

    sheetLoanPurpose

  } = useWatch({ control });




  useEffect(() => {

    setValue('sheetTransactionSalePrice', sheetSalePrice)
    setValue('salePrice', sheetSalePrice)
    setValue('sheetTransactionReserves', sheetFundReserves)
    setValue('sheetTransactionIssueDate', sheetIssueDate)
    setValue('sheetTransactionLoanAmount', sheetLoanAmount)
    setValue('loanAmount', sheetLoanAmount)
    setValue('sheetTransactionLoanTerm', sheetLoanTerm)
    setValue('sheetTransactionInterestRate', sheetInterestRate)
    setValue('sheetTransactionAnnualPercentageRate', sheetAnnualPercentageRate)
    setValue('sheetTransactionLoanToValue', sheetLoanToValue)
    setValue('sheetTransactionPrincipalAndInterest', sheetPrincipalAndInterest)
    setValue('sheetTransactionOtherFinancing', sheetOtherFinancing)
    setValue('sheetTransactionOthers', sheetOthers)
    setValue('sheetTransactionHazardInsurance', sheetHazardInsurance)
    setValue('sheetTransactionPropertyTaxes', sheetPropertyTaxes)
    setValue('sheetTransactionMortgageInsurance', sheetMortgageInsurance)
    setValue('sheetTransactionHOADues', sheetHOADues)
    setValue('sheetOriginationDiscountPoints', points)

  }, [
    sheetSalePrice,
    sheetFundReserves,
    sheetIssueDate,
    sheetLoanAmount,
    sheetLoanTerm,
    sheetInterestRate,
    sheetAnnualPercentageRate,
    sheetLoanToValue,
    sheetPrincipalAndInterest,
    sheetOtherFinancing,
    sheetOthers,
    sheetHazardInsurance,
    sheetPropertyTaxes,
    sheetMortgageInsurance,
    sheetHOADues,
    points
  ])




  const handleSheetDownPayPerBlur = () => {


    if (parseFloat(sheetFundDownPayment) <= 100) {
      const loanAmt = parseFloat(sheetSalePrice) - (parseFloat(sheetSalePrice) * parseFloat(sheetFundDownPayment) / 100)
      setValue('sheetLoanAmount', Math.floor(loanAmt + 0.5))
      setValue('loanAmount', Math.floor(loanAmt + 0.5))
      setValue('sheetLoanToValue', (loanAmt / sheetSalePrice * 100).toFixed(2))
    }

  }


  useEffect(() => {

    const loanAmount = parseFloat(parseFloat(leadData?.leadInfo?.propertyValue) - parseFloat(leadData?.leadInfo?.downPaymentFixed)) || 0;
    const downPayAmount = parseFloat(leadData?.leadInfo?.downPaymentFixed) || 0;
    const fundingFee = parseFloat(sheetFundFundingFee) || 0;
    const originationAdminFee = parseFloat(sheetOriginationAdministrationFee) || 0;
    const originationDiscountPoints = parseFloat(sheetOriginationDiscountPoints) || 0;
    const lenderAppraisalFee = parseFloat(sheetLenderAppraisalFee) || 0;
    const lenderCreditReportFee = parseFloat(sheetLenderCreditReportFee) || 0;
    const lenderFloodCertificationFee = parseFloat(sheetLenderFloodCertificationFee) || 0;
    const lenderMERSFee = parseFloat(sheetLenderMERSFee) || 0;
    const lenderNewDebtMonitoring = parseFloat(sheetLenderNewDebtMonitoring) || 0;
    const lenderTaxServiceFee = parseFloat(sheetLenderTaxServiceFee) || 0;
    const titleCountyTransferTax = parseFloat(sheetTitleCountyTransferTax) || 0;
    const titleRecordingFee = parseFloat(sheetTitleRecordingFee) || 0;
    const titleInsurance = parseFloat(sheetTitleInsurance) || 0;
    const titleCourierFee = parseFloat(sheetTitleCourierFee) || 0;
    const titleMobileNotaryFee = parseFloat(sheetTitleMobileNotaryFee) || 0;
    const titleSettlement = parseFloat(sheetTitleSettlement) || 0;
    const prePaidItemsPerDiemInterest = parseFloat(sheetPrePaidItemsPerDiemInterest) || 0;
    const prePaidItemsEstimatedReserves = parseFloat(sheetPrePaidItemsEstimatedReserves) || 0;

    const creditsEarnestMoney = parseFloat(sheetCreditsEarnestMoney) || 0;
    const creditsSellerCredit = parseFloat(sheetCreditsSellerCredit) || 0;
    const creditsLenderCredit = parseFloat(sheetCreditsLenderCredit) || 0;
    const creditsSubordinateFinancing = parseFloat(sheetCreditsSubordinateFinancing) || 0;


    const closingCosts = (
      downPayAmount +
      fundingFee +
      originationAdminFee +
      originationDiscountPoints +

      lenderAppraisalFee +
      lenderCreditReportFee +
      lenderFloodCertificationFee +
      lenderMERSFee +
      lenderNewDebtMonitoring +
      lenderTaxServiceFee +

      titleCountyTransferTax +
      titleRecordingFee +
      titleInsurance +
      titleCourierFee +
      titleMobileNotaryFee +
      titleSettlement +

      prePaidItemsPerDiemInterest +
      prePaidItemsEstimatedReserves
    );


    const credits = (
      creditsEarnestMoney +
      creditsSellerCredit +
      creditsLenderCredit +
      creditsSubordinateFinancing
    );


    const sheetFundClosingCosts = (closingCosts);
    setValue('sheetFundClosingCosts', sheetFundClosingCosts.toFixed(2));

  }, [

    sheetFundFundingFee,
    sheetOriginationAdministrationFee,
    sheetOriginationDiscountPoints,
    sheetLenderAppraisalFee,
    sheetLenderCreditReportFee,
    sheetLenderFloodCertificationFee,
    sheetLenderMERSFee,
    sheetLenderNewDebtMonitoring,
    sheetLenderTaxServiceFee,
    sheetTitleCountyTransferTax,
    sheetTitleRecordingFee,
    sheetTitleInsurance,
    sheetTitleCourierFee,
    sheetTitleMobileNotaryFee,
    sheetTitleSettlement,
    sheetPrePaidItemsPerDiemInterest,
    sheetPrePaidItemsEstimatedReserves,
    sheetCreditsEarnestMoney,
    sheetCreditsSellerCredit,
    sheetCreditsLenderCredit,
    sheetCreditsSubordinateFinancing
  ]);



  useEffect(() => {

    const originationFee = isNaN(sheetOriginationAdministrationFee) ? 0 : parseFloat(sheetOriginationAdministrationFee);
    const discountPoints = isNaN(sheetOriginationDiscountPoints) ? 0 : parseFloat(sheetOriginationDiscountPoints);
    const monitoringFee = isNaN(sheetLenderNewDebtMonitoring) ? 0 : parseFloat(sheetLenderNewDebtMonitoring);
    const interestRate = isNaN(sheetInterestRate) ? 0 : parseFloat(sheetInterestRate);
    const loanTerm = isNaN(sheetLoanTerm) ? 0 : parseFloat(sheetLoanTerm);
    const loanAmount = isNaN(sheetLoanAmount) ? 0 : parseFloat(sheetLoanAmount);
    const salePr = isNaN(sheetSalePrice) ? 0 : parseFloat(sheetSalePrice);


    const ADMIN_FEE_PLUS_DISCOUNT_PLUS_OTHER_FEE = originationFee + discountPoints + monitoringFee
    const LOAN_AMT_MULTIPLY_TERM_MULTIPLY_RATE = interestRate * 0.01 * loanTerm * loanAmount
    const APR = ((ADMIN_FEE_PLUS_DISCOUNT_PLUS_OTHER_FEE + LOAN_AMT_MULTIPLY_TERM_MULTIPLY_RATE) / loanAmount) / (365 * loanTerm) * (365 * 100)


    if (loanAmount <= salePr) {
      setValue('sheetAnnualPercentageRate', APR.toFixed(3));
      setValue('sheetLoanToValue', (loanAmount / salePr * 100).toFixed(2));
      setValue('sheetFundDownPayment', (100 - (loanAmount / salePr * 100).toFixed(2)))
    }


  }, [sheetOriginationAdministrationFee, sheetLenderNewDebtMonitoring, sheetOriginationDiscountPoints, sheetInterestRate, sheetLoanTerm, sheetLoanAmount]);


  useEffect(() => {

    setValue('sheetInterestRate', interestRate)

  }, [interestRate])

  useEffect(() => {
    const loanAmountValue = parseFloat(sheetLoanAmount);
    const rateMortgagePaymentValue = parseFloat(sheetInterestRate);
    const loanTermValue = parseInt(sheetLoanTerm);



    if (
      !isNaN(loanAmountValue) &&
      !isNaN(rateMortgagePaymentValue) &&
      loanTermValue <= 100
    ) {

      const monthlyInterestRate = (rateMortgagePaymentValue / 100) / 12;
      const denominator = Math.pow(1 + monthlyInterestRate, loanTermValue * 12) - 1;
      const numerator = (loanAmountValue * monthlyInterestRate * Math.pow(1 + monthlyInterestRate, loanTermValue * 12));
      const firstPayment = numerator / denominator;
      setValue('mmpAmountPerMonth', firstPayment.toFixed(2));
      setValue('sheetPrincipalAndInterest', firstPayment.toFixed(2));

    }
  }, [sheetLoanAmount, sheetInterestRate, sheetLoanTerm]);

  const handleSSNChange = (e) => {
    const formattedSSN = e.target.value
      .replace(/\D/g, '')
      .slice(0, 9)
      .replace(/^(\d{3})(\d{2})(\d{4})$/, '$1-$2-$3');

    setValue('ssn', formattedSSN);
  };


  useEffect(() => {

    if (sheetLoanPurpose === 'Purchasing') {
      setValue('totalLiabilities', leadData?.leadInfo?.totalLiabilities)


    }
    else if (sheetLoanPurpose === 'Refinancing') {
      const downPay = (parseFloat(sheetSalePrice) - parseFloat(sheetLoanAmount))
      const downPer = downPay * 100 / parseFloat(sheetSalePrice)
      setValue('totalLiabilities', leadData?.leadInfo?.monthlyMortgageExpense)

      setValue('sheetFundDownPayment', downPer.toFixed(2))
    }

  }, [sheetLoanPurpose])


  const handleSheetSalePriceBlur = () => {




    const salePr = parseFloat(sheetSalePrice)
    const downPayPer = parseFloat(sheetFundDownPayment)

    const downPayAmount = (salePr * downPayPer / 100).toFixed(2)
    const loanAmt = salePr - downPayAmount
    setValue('sheetLoanAmount', Math.floor(loanAmt + 0.5))
    setValue('loanAmount', Math.floor(loanAmt + 0.5))
    setValue('sheetLoanToValue', (loanAmt / salePr * 100).toFixed(2))


  }

  const [formatWorkPhone, setFormatWorkPhone] = useState(formatPhoneNumber(leadData?.leadInfo?.phone));
  const [unformatWorkPhone, setUnformatWorkPhone] = useState(leadData?.leadInfo?.phone || '');


  function formatPhoneNumber(phoneNumber) {
    if (!phoneNumber) return '';
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return phoneNumber;
  }

  function handleWorkPhoneChange(event) {
    let unformattedValue = event.target.value.replace(/\D/g, '');

    if (unformattedValue.length > 10) {
      unformattedValue = unformattedValue.slice(0, 10);
    }

    setUnformatWorkPhone(unformattedValue);
    setFormatWorkPhone(formatPhoneNumber(unformattedValue));
  }

  function handleWorkPhoneChangeDB() {
    setValue('phone', unformatWorkPhone)
  }

  const [formatSheetWorkPhone, setFormatSheetWorkPhone] = useState(formatPhoneNumber(leadData?.leadForm?.closingCostWorksheet?.sheetPhone || profileData?.phone || ""));
  const [unformatSheetWorkPhone, setUnformatSheetWorkPhone] = useState(leadData?.leadForm?.closingCostWorksheet?.sheetPhone || profileData?.phone || "" || '');




  function handleSheetWorkPhoneChange(event) {
    let unformattedValue = event.target.value.replace(/\D/g, '');

    if (unformattedValue.length > 10) {
      unformattedValue = unformattedValue.slice(0, 10);
    }

    setUnformatSheetWorkPhone(unformattedValue);
    setFormatSheetWorkPhone(formatPhoneNumber(unformattedValue));
  }

  function handleSheetWorkPhoneChangeDB() {
    setValue('sheetPhone', unformatSheetWorkPhone)
  }






  return (
    <MainLoyOut>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container px={5} pb={5} pt={3} direction={"column"} spacing={4}>

          <CardContent>
            <Grid container direction={"column"} spacing={0} ml={2}>
              <Grid item container direction={"column"}>
                <Grid item>
                  <Typography variant="h3" mb={1} fontWeight={700}>Lead info</Typography>
                </Grid>
                <Grid
                  item
                  container
                  direction={"row"}
                  justifyContent={"space-between"}
                >
                  <Grid item xs={8} md={5}>

                    <Controller
                      name="firstName"
                      control={control}
                      rules={QuoteFormValidation.firstName}
                      render={({ field }) => (
                        <CustomInput
                          style={{ backgroundColor: 'white', borderRadius: '8px' }}
                          {...field}
                          type={'text'}
                          required
                          boxProps={{ my: 2 }}
                          size="small"
                          fullWidth
                          label={"First Name"}
                          placeholder={"Enter your first name"}
                          error={errors?.firstName?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={8} md={5}>

                    <Controller
                      name="lastName"
                      control={control}
                      rules={QuoteFormValidation.lastName}
                      render={({ field }) => (
                        <CustomInput
                          style={{ backgroundColor: 'white', borderRadius: '8px' }}
                          {...field}
                          type={'text'}
                          required
                          boxProps={{ my: 2 }}
                          size="small"
                          fullWidth
                          label={"Last Name"}
                          placeholder={"Enter your last name"}
                          error={errors?.lastName?.message}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  direction={"row"}
                  justifyContent={"space-between"}
                >
                  <Grid item xs={8} md={5}
                  >
                    <Controller
                      name="email"
                      control={control}
                      rules={QuoteFormValidation.email}
                      render={({ field }) => (
                        <CustomInput
                          style={{ backgroundColor: 'white', borderRadius: '8px' }}
                          {...field}
                          type={'email'}
                          required
                          boxProps={{ my: 2 }}
                          size="small"
                          fullWidth
                          label={"Email Id"}
                          placeholder={"Enter your email id"}
                          error={errors?.email?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={8} md={5}>
                    <Controller
                      name="dateOfBirth"
                      control={control}
                      rules={QuoteFormValidation.dateOfBirth}
                      render={({ field }) => (
                        <CustomInput
                          style={{ backgroundColor: 'white', borderRadius: '8px' }}
                          {...field}
                          type={'date'}
                          required
                          boxProps={{ my: 2 }}
                          size="small"
                          fullWidth
                          label={"Date of Birth"}
                          placeholder={"Mar-15-1994"}
                          error={errors?.dateOfBirth?.message}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  direction={"row"}
                  justifyContent={"space-between"}
                >
                  <Grid item xs={8} md={5}>
                    <Controller
                      name="phone"
                      control={control}
                      rules={QuoteFormValidation.phone}
                      render={({ field }) => (
                        <CustomInput
                          style={{ backgroundColor: 'white', borderRadius: '8px' }}
                          {...field}
                          type={'text'}
                          required
                          sicon={<CallOutlinedIcon />}
                          boxProps={{ my: 2 }}
                          size="small"
                          fullWidth
                          label={"Mobile Number"}
                          placeholder={"(887) 509 - 5904"}
                          error={errors?.phone?.message}
                          value={formatWorkPhone}
                          onChange={handleWorkPhoneChange}
                          onBlur={handleWorkPhoneChangeDB}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={8} md={5}>
                    <Controller
                      name="ssn"
                      control={control}
                      rules={QuoteFormValidation.ssn}
                      render={({ field }) => (
                        <CustomInput
                          style={{ backgroundColor: 'white', borderRadius: '8px' }}
                          {...field}
                          type={'text'}
                          required
                          boxProps={{ my: 2 }}
                          size="small"
                          fullWidth
                          label={"Enter your SSN"}
                          error={errors?.ssn?.message}
                          onChange={handleSSNChange}
                          pattern="\d{3}-?\d{2}-?\d{4}" // valid SSN format
                          placeholder={"AAA-GG-SSSS"}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  direction={"row"}
                  justifyContent={"space-between"}
                >
                  <Grid item xs={8} md={5}>
                    <Controller
                      name="monthlyIncome"
                      control={control}
                      rules={QuoteFormValidation.monthlyIncome}
                      render={({ field }) => (
                        <CustomInput
                          style={{ backgroundColor: 'white', borderRadius: '8px' }}
                          {...field}
                          type={'text'}
                          required

                          boxProps={{ my: 2 }}
                          size="small"
                          fullWidth
                          label={"Total Monthly Income"}
                          placeholder={"eg. $10000"}
                          error={errors?.monthlyIncome?.message}
                          sicon={<AttachMoney />} value={formatNumericValue(field.value)}
                          onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={8} md={5}>
                    <Controller
                      name="totalLiabilities"
                      control={control}
                      rules={QuoteFormValidation.totalLiabilities}
                      render={({ field }) => (
                        <CustomInput
                          style={{ backgroundColor: 'white', borderRadius: '8px' }}
                          {...field}
                          type={'text'}
                          required
                          boxProps={{ my: 2 }}
                          size="small"
                          fullWidth
                          label={"Total Liabilities"}
                          placeholder={"eg. $5000"}
                          error={errors?.totalLiabilities?.message}
                          sicon={<AttachMoney />} value={formatNumericValue(field.value)}
                          onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Divider />
              </Grid>





              <Grid item container direction={"column"} mt={5} mb={1}>
                <Grid item>
                  <Typography variant="h3">Fill by MLO/Broker/MLP</Typography>
                </Grid>
                <Grid
                  item
                  container
                  direction={"row"}
                  justifyContent={"space-between"}
                >
                  <Grid item xs={8} md={5}>
                    <Controller
                      name="interestRate"
                      control={control}
                      rules={QuoteFormValidation.interestRate}
                      render={({ field }) => (
                        <CustomInput
                          style={{ backgroundColor: 'white', borderRadius: '8px' }}
                          {...field}
                          type={'number'}
                          required
                          boxProps={{ my: 2 }}
                          size="small"
                          fullWidth
                          label={"Interst Rate"}
                          placeholder={"eg. 6.000%"}
                          error={errors?.interestRate?.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={8} md={5}>
                    <Controller
                      name="points"
                      control={control}
                      rules={QuoteFormValidation.points}
                      render={({ field }) => (
                        <CustomInput
                          style={{ backgroundColor: 'white', borderRadius: '8px' }}
                          {...field}
                          type={'number'}
                          required
                          boxProps={{ my: 2 }}
                          size="small"
                          fullWidth
                          label={"Credits or Points"}
                          placeholder={"eg. $10,000"}
                          error={errors?.points?.message}

                        />
                      )}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  direction={"row"}
                  justifyContent={"space-between"}
                >
                  <Grid item xs={8} md={5}>
                    <Controller
                      name="pmiAmountPerMonth"
                      control={control}

                      render={({ field }) => (
                        <CustomInput
                          style={{ backgroundColor: 'white', borderRadius: '8px' }}
                          {...field}
                          type={'text'}
                          boxProps={{ my: 2 }}
                          size="small"
                          fullWidth
                          label={"Primary Mortgage Insurance Amount per month"}
                          placeholder={"eg. $200"}
                          error={errors?.pmiAmountPerMonth?.message}
                          sicon={<AttachMoney />} value={formatNumericValue(field.value)}
                          onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={8} md={5}>
                    <Controller
                      name="mmpAmountPerMonth"
                      control={control}
                      rules={QuoteFormValidation.mmpAmountPerMonth}
                      render={({ field }) => (
                        <CustomInput
                          style={{ backgroundColor: 'white', borderRadius: '8px' }}
                          {...field}
                          type={'text'}
                          required
                          boxProps={{ my: 2 }}
                          size="small"
                          fullWidth
                          label={"Monthly Mortgage payment Amount per month"}
                          placeholder={"eg. $4,000"}
                          disabled={true}
                          error={errors?.mmpAmountPerMonth?.message}
                          sicon={<AttachMoney />} value={formatNumericValue(field.value)}
                          onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
          <Grid item>
            <Divider />
          </Grid>

          <Grid item>

            <CardActions onClick={handleExpandClick} disableSpacing style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="h3" fontWeight={700}>Closing Cost Worksheet</Typography>

              <CustomTooltip title={expanded ? "Show less" : "Please click here to complete the entire quote form."}>
                <div>
                  <ExpandMore
                    expand={expanded}

                    aria-expanded={expanded}
                    aria-label="show more"
                  >
                    <ExpandMoreIcon />
                  </ExpandMore>
                </div>
              </CustomTooltip>
            </CardActions>


            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CardContent>
                <Grid container direction={"column"} spacing={5}>
                  <Grid item container direction={"column"}>
                    <Grid item>
                      <Typography variant="h3" pt={'20px'}>MLO Personal</Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      direction={"row"}
                      justifyContent={"space-between"}
                    >
                      <Grid item xs={8} md={5}>
                        <Controller
                          name="sheetFirstName"
                          control={control}
                          rules={QuoteFormValidation.sheetFirstName}
                          render={({ field }) => (
                            <CustomInput
                              style={{ backgroundColor: 'white', borderRadius: '8px' }}
                              {...field}
                              type={'text'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"First Name"}
                              placeholder={"Enter your first name"}
                              error={errors?.sheetFirstName?.message}
                              disabled={true}
                            />
                          )}
                        />

                      </Grid>
                      <Grid item xs={8} md={5}>
                        <Controller
                          name="sheetLastName"
                          control={control}
                          rules={QuoteFormValidation.sheetLastName}
                          render={({ field }) => (
                            <CustomInput
                              style={{ backgroundColor: 'white', borderRadius: '8px' }}
                              {...field}
                              type={'text'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Last Name"}
                              placeholder={"Enter your last name"}
                              error={errors?.sheetLastName?.message}
                              disabled={true}
                            />
                          )}
                        />

                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      direction={"row"}
                      justifyContent={"space-between"}
                    >
                      <Grid item xs={8} md={5}>
                        <Controller
                          name="sheetEmail"
                          control={control}
                          rules={QuoteFormValidation.sheetEmail}
                          render={({ field }) => (
                            <CustomInput
                              style={{ backgroundColor: 'white', borderRadius: '8px' }}
                              {...field}
                              type={'email'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Email Id"}
                              placeholder={"Enter your email id"}
                              error={errors?.sheetEmail?.message}
                              disabled={true}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={8} md={5}>
                        <Controller
                          name="sheetNMLS"
                          control={control}
                          rules={QuoteFormValidation.sheetNMLS}
                          render={({ field }) => (
                            <CustomInput
                              style={{ backgroundColor: 'white', borderRadius: '8px' }}
                              {...field}
                              type={'number'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"NMLS ID"}
                              placeholder={"Enter your NMLS id"}
                              error={errors?.sheetNMLS?.message}
                              disabled={true}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      direction={"row"}
                      justifyContent={"space-between"}
                    >
                      <Grid item xs={8} md={5}>
                        <Controller
                          name="sheetPhone"
                          control={control}
                          rules={QuoteFormValidation.sheetPhone}
                          render={({ field }) => (
                            <CustomInput
                              style={{ backgroundColor: 'white', borderRadius: '8px' }}
                              {...field}
                              type={'text'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              sicon={<CallOutlinedIcon />}
                              label={"Mobile Number"}
                              placeholder={"(887) 509 - 5904"}
                              error={errors?.sheetPhone?.message}
                              disabled={true}
                              value={formatSheetWorkPhone}
                              onChange={handleSheetWorkPhoneChange}
                              onBlur={handleSheetWorkPhoneChangeDB}
                            />
                          )}
                        />
                      </Grid>

                    </Grid>
                  </Grid>
                  <Grid item>
                    <Divider />
                  </Grid>

                  <Grid item container direction={"column"}>
                    <Grid item mb={1}>
                      <Typography variant="h3">Loan details</Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      direction={"row"}
                      justifyContent={"space-between"}
                    >

                      <Grid item container xs={8} md={5} direction={"column"}>
                        <Grid item>
                          <InputLabel>
                            Loan Purpose
                            <Typography variant="small" color="error">
                              *
                            </Typography>
                          </InputLabel>
                        </Grid>
                        <Grid item>
                          <Controller
                            name='sheetLoanPurpose'
                            control={control}
                            rules={QuoteFormValidation.sheetLoanPurpose}
                            render={({ field }) => (
                              <RadioButtonGroup
                                field={field}
                                data={PURPOSE_LIST}
                                style={{ flexWrap: 'nowrap', display: 'flex' }}
                                direction="row"
                                labelColor={true}
                                error={errors?.sheetLoanPurpose?.message}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                      <Grid item container xs={8} md={5} direction={"column"}>
                        <Grid item>
                          <InputLabel>
                            Loan Type
                            <Typography variant="small" color="error">
                              *
                            </Typography>
                          </InputLabel>
                        </Grid>
                        <Grid item>
                          <Controller
                            name='sheetLoanType'
                            control={control}
                            rules={QuoteFormValidation.sheetLoanType}
                            render={({ field }) => (
                              <RadioButtonGroup
                                field={field}
                                data={LOAN_TYPE_LIST}
                                style={{ flexWrap: 'nowrap', display: 'flex' }}
                                direction="row"
                                labelColor={true}
                                error={errors?.sheetLoanType?.message}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      direction={"row"}
                      justifyContent={"space-between"}
                    >

                      <Grid item xs={8} md={5}>
                        <Controller
                          name="sheetPropertyAddress"
                          control={control}
                          rules={QuoteFormValidation.sheetPropertyAddress}
                          render={({ field }) => (
                            <CustomInput
                              style={{ backgroundColor: 'white', borderRadius: '8px' }}
                              {...field}
                              type={'text'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Property Address"}
                              placeholder={"166 Barnhill Dr"}
                              error={errors?.sheetPropertyAddress?.message}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={8} md={5}>
                        <Controller
                          name="sheetZip"
                          control={control}
                          rules={QuoteFormValidation.sheetZip}
                          render={({ field }) => (
                            <CustomInput
                              style={{ backgroundColor: 'white', borderRadius: '8px' }}
                              {...field}
                              type={'number'}
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Property ZIP Code"}
                              placeholder={"Enter your property ZIP code"}
                              error={errors?.sheetZip?.message}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      direction={"row"}
                      justifyContent={"space-between"}
                    >
                      <Grid item xs={8} md={5}>
                        <Controller
                          name="sheetSalePrice"
                          control={control}
                          rules={QuoteFormValidation.sheetSalePrice}
                          render={({ field }) => (
                            <CustomInput
                              style={{ backgroundColor: 'white', borderRadius: '8px' }}
                              {...field}
                              type={'text'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Estimated Property Value/Purchase Price/Sales Price"}
                              placeholder={"Enter the sale price"}
                              error={errors?.sheetSalePrice?.message}
                              onBlur={handleSheetSalePriceBlur}
                              sicon={<AttachMoney />} value={formatNumericValue(field.value)}
                              onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={8} md={5}>

                        <Controller
                          name="sheetIssueDate"
                          control={control}
                          rules={QuoteFormValidation.sheetIssueDate}
                          render={({ field }) => {
                            if (!field.value) {
                              field.onChange(new Date().toISOString().split('T')[0]);
                            }
                            return (
                              <CustomInput
                                style={{ backgroundColor: 'white', borderRadius: '8px' }}
                                {...field}
                                type={'date'}
                                required
                                boxProps={{ my: 2 }}
                                size="small"
                                fullWidth
                                label={"Date Issued"}
                                placeholder={"25/56/2023"}
                                error={errors?.sheetIssueDate?.message}
                                disabled={true}
                              />
                            );
                          }}
                        />

                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      direction={"row"}
                      justifyContent={"space-between"}
                    >
                      <Grid item xs={8} md={5}>
                        <Controller
                          name="sheetLoanAmount"
                          control={control}
                          rules={QuoteFormValidation.sheetLoanAmount}
                          render={({ field }) => (
                            <CustomInput
                              style={{ backgroundColor: 'white', borderRadius: '8px' }}
                              {...field}
                              type={'text'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Loan Amount"}
                              placeholder={"Loan Amount"}
                              error={errors?.sheetLoanAmount?.message}
                              sicon={<AttachMoney />} value={formatNumericValue(field.value)}
                              onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                            />
                          )}
                        />

                      </Grid>
                      <Grid item xs={8} md={5}>
                        <Controller
                          name="sheetLoanTerm"
                          control={control}
                          rules={QuoteFormValidation.sheetLoanTerm}
                          render={({ field }) => (
                            <CustomDropDown
                              style={{ backgroundColor: 'white', borderRadius: '8px' }}
                              {...field}
                              field={field}
                              type={'text'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Loan Term"}
                              state={sheetLoanTerm}
                              option={LOAN_TERM_TYPE}
                              placeholder={"convensional 30 Years"}
                              error={errors?.sheetLoanTerm?.message}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      direction={"row"}
                      justifyContent={"space-between"}
                    >
                      <Grid item xs={8} md={5}>
                        <Controller
                          name="sheetInterestRate"
                          control={control}
                          rules={QuoteFormValidation.sheetInterestRate}
                          render={({ field }) => (
                            <CustomInput
                              style={{ backgroundColor: 'white', borderRadius: '8px' }}
                              {...field}
                              type={'number'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Interest Rate (%)"}
                              placeholder={"eg. 5.23%"}
                              error={errors?.sheetInterestRate?.message}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={8} md={5}>
                        <Controller
                          name="sheetAnnualPercentageRate"
                          control={control}
                          rules={QuoteFormValidation.sheetAnnualPercentageRate}
                          render={({ field }) => (
                            <CustomInput
                              style={{ backgroundColor: 'white', borderRadius: '8px' }}
                              {...field}
                              type={'number'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Annual Percentage Rate (%)"}
                              placeholder={"eg. 5.88%"}
                              error={errors?.sheetAnnualPercentageRate?.message}
                              disabled={true}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      direction={"row"}
                      justifyContent={"space-between"}
                    >
                      <Grid item xs={8} md={5}>
                        <Controller
                          name="sheetLoanToValue"
                          control={control}
                          rules={QuoteFormValidation.sheetLoanToValue}
                          render={({ field }) => (
                            <CustomInput
                              style={{ backgroundColor: 'white', borderRadius: '8px' }}
                              {...field}
                              type={'number'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Loan to Value (LTV)"}
                              placeholder={"eg. 80.00%"}
                              error={errors?.sheetLoanToValue?.message}
                              disabled={true}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={8} md={5}>
                        <Controller
                          name="sheetPrincipalAndInterest"
                          control={control}

                          render={({ field }) => (
                            <CustomInput
                              style={{ backgroundColor: 'white', borderRadius: '8px' }}
                              {...field}
                              type={'text'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Principal and Interest (P&I)"}
                              placeholder={"eg. $3323"}
                              error={errors?.sheetPrincipalAndInterest?.message}
                              sicon={<AttachMoney />} value={formatNumericValue(field.value)}
                              onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                              disabled={true}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      direction={"row"}
                      justifyContent={"space-between"}
                    >
                      <Grid item xs={8} md={5}>
                        <Controller
                          name="sheetOtherFinancing"
                          control={control}

                          render={({ field }) => (
                            <CustomInput
                              style={{ backgroundColor: 'white', borderRadius: '8px' }}
                              {...field}
                              type={'text'}

                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Other Financing (P&I)"}
                              placeholder={"eg. $1000"}
                              error={errors?.sheetOtherFinancing?.message}
                              sicon={<AttachMoney />} value={formatNumericValue(field.value)}
                              onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={8} md={5}>
                        <Controller
                          name="sheetOthers"
                          control={control}

                          render={({ field }) => (
                            <CustomInput
                              style={{ backgroundColor: 'white', borderRadius: '8px' }}
                              {...field}
                              type={'text'}
                              //  required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Others"}
                              placeholder={"eg. $600"}
                              error={errors?.sheetOthers?.message}
                              sicon={<AttachMoney />} value={formatNumericValue(field.value)}
                              onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      direction={"row"}
                      justifyContent={"space-between"}
                    >
                      <Grid item xs={8} md={5}>
                        <Controller
                          name="sheetHazardInsurance"
                          control={control}
                          rules={QuoteFormValidation.sheetHazardInsurance}
                          render={({ field }) => (
                            <CustomInput
                              style={{ backgroundColor: 'white', borderRadius: '8px' }}
                              {...field}
                              type={'text'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Hazard Insurance/Flood"}
                              placeholder={"eg. $2000"}
                              error={errors?.sheetHazardInsurance?.message}
                              sicon={<AttachMoney />} value={formatNumericValue(field.value)}
                              onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={8} md={5}>
                        <Controller
                          name="sheetPropertyTaxes"
                          control={control}
                          rules={QuoteFormValidation.sheetPropertyTaxes}
                          render={({ field }) => (
                            <CustomInput
                              style={{ backgroundColor: 'white', borderRadius: '8px' }}
                              {...field}
                              type={'text'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Property Taxes"}
                              placeholder={"eg. $1079"}
                              error={errors?.sheetPropertyTaxes?.message}
                              sicon={<AttachMoney />} value={formatNumericValue(field.value)}
                              onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      direction={"row"}
                      justifyContent={"space-between"}
                    >
                      <Grid item xs={8} md={5}>
                        <Controller
                          name="sheetMortgageInsurance"
                          control={control}

                          render={({ field }) => (
                            <CustomInput
                              style={{ backgroundColor: 'white', borderRadius: '8px' }}
                              {...field}
                              type={'text'}

                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Mortgage Insurance"}
                              placeholder={"eg. $1000"}
                              error={errors?.sheetMortgageInsurance?.message}
                              sicon={<AttachMoney />} value={formatNumericValue(field.value)}
                              onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={8} md={5}>
                        <Controller
                          name="sheetHOADues"
                          control={control}

                          render={({ field }) => (
                            <CustomInput
                              style={{ backgroundColor: 'white', borderRadius: '8px' }}
                              {...field}
                              type={'text'}

                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"HOA Dues"}
                              placeholder={"eg. $500"}
                              error={errors?.sheetHOADues?.message}
                              sicon={<AttachMoney />} value={formatNumericValue(field.value)}
                              onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Divider />
                  </Grid>

                  <Grid item container direction={"column"}>
                    <Grid item>
                      <Typography variant="h3">
                        Estimated Funds to Close
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      direction={"row"}
                      justifyContent={"space-between"}
                    >
                      <Grid item xs={8} md={5}>
                        <Controller
                          name="sheetFundDownPayment"
                          control={control}
                          rules={QuoteFormValidation.sheetFundDownPayment}
                          render={({ field }) => (
                            <CustomInput
                              style={{ backgroundColor: 'white', borderRadius: '8px' }}
                              {...field}
                              type={'number'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Down Payment %"}
                              placeholder={"eg. $1000"}
                              error={errors?.sheetFundDownPayment?.message}
                              onBlur={handleSheetDownPayPerBlur}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={8} md={5}>
                        <Controller
                          name="sheetFundClosingCosts"
                          control={control}
                          rules={QuoteFormValidation.sheetFundClosingCosts}
                          render={({ field }) => (
                            <CustomTooltip title="Indicate the cost of Private Mortgage Insurance (PMI), Mortgage Insurance Premium (MIP), or Funding Fee."
                            >
                              <div>
                                <CustomInput
                                  style={{ backgroundColor: 'white', borderRadius: '8px' }}
                                  {...field}
                                  type={'text'}
                                  required
                                  boxProps={{ my: 2 }}
                                  size="small"
                                  fullWidth
                                  label={"Closing Costs (excludes PMI/MIP/FF)"}
                                  placeholder={"eg. $18,315"}
                                  error={errors?.sheetFundClosingCosts?.message}
                                  sicon={<AttachMoney />} value={formatNumericValue(field.value)}
                                  onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                                  disabled={true}
                                />
                              </div>
                            </CustomTooltip>
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      direction={"row"}
                      justifyContent={"space-between"}
                    >
                      <Grid item xs={8} md={5}>
                        <Controller
                          name="sheetFundReserves"
                          control={control}
                          render={({ field }) => (
                            <CustomInput
                              style={{ backgroundColor: 'white', borderRadius: '8px' }}
                              {...field}
                              type={'text'}

                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Pre-paid Items/Reserves"}
                              placeholder={"eg. $175"}
                              error={errors?.sheetFundReserves?.message}
                              sicon={<AttachMoney />} value={formatNumericValue(field.value)}
                              onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={8} md={5}>
                        <Controller
                          name="sheetFundFundingFee"
                          control={control}

                          render={({ field }) => (
                            <CustomInput
                              style={{ backgroundColor: 'white', borderRadius: '8px' }}
                              {...field}
                              type={'text'}

                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"PMI, MIP, Funding Fee"}
                              placeholder={"eg. $1000"}
                              error={errors?.sheetFundFundingFee?.message}
                              sicon={<AttachMoney />} value={formatNumericValue(field.value)}
                              onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Divider />
                  </Grid>

                  <Grid item container direction={"column"}>
                    <Grid item>
                      <Typography variant="h3">Credits</Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      direction={"row"}
                      justifyContent={"space-between"}
                    >
                      <Grid item xs={8} md={5}>
                        <Controller
                          name="sheetCreditsEarnestMoney"
                          control={control}

                          render={({ field }) => (
                            <CustomInput
                              style={{ backgroundColor: 'white', borderRadius: '8px' }}
                              {...field}
                              type={'text'}

                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Earnest Money"}
                              placeholder={"eg. $10,000"}
                              error={errors?.sheetCreditsEarnestMoney?.message}
                              sicon={<AttachMoney />} value={formatNumericValue(field.value)}
                              onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={8} md={5}>
                        <Controller
                          name="sheetCreditsSellerCredit"
                          control={control}

                          render={({ field }) => (
                            <CustomInput
                              style={{ backgroundColor: 'white', borderRadius: '8px' }}
                              {...field}
                              type={'text'}

                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Seller Credit"}
                              placeholder={"eg. $10,000"}
                              error={errors?.sheetCreditsSellerCredit?.message}
                              sicon={<AttachMoney />} value={formatNumericValue(field.value)}
                              onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      direction={"row"}
                      justifyContent={"space-between"}
                    >
                      <Grid item xs={8} md={5}>
                        <Controller
                          name="sheetCreditsLenderCredit"
                          control={control}

                          render={({ field }) => (
                            <CustomInput
                              style={{ backgroundColor: 'white', borderRadius: '8px' }}
                              {...field}
                              type={'text'}

                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Lender Credit"}
                              placeholder={"eg. $20000"}
                              error={errors?.sheetCreditsLenderCredit?.message}
                              sicon={<AttachMoney />} value={formatNumericValue(field.value)}
                              onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={8} md={5}>
                        <Controller
                          name="sheetCreditsSubordinateFinancing"
                          control={control}

                          render={({ field }) => (
                            <CustomInput
                              style={{ backgroundColor: 'white', borderRadius: '8px' }}
                              {...field}
                              type={'text'}

                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Subordinate Financing"}
                              placeholder={"eg. $5000"}
                              error={errors?.sheetCreditsSubordinateFinancing?.message}
                              sicon={<AttachMoney />} value={formatNumericValue(field.value)}
                              onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Divider />
                  </Grid>

                  <Grid item container direction={"column"}>
                    <Grid item>
                      <Typography variant="h3">
                        Estimated Closing Costs
                      </Typography>
                    </Grid>

                    <Grid item container py={2}>
                      <Grid item>
                        <Typography variant="body2" fontWeight={"bold"}>
                          Origination Charges
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        container
                        direction={"row"}
                        justifyContent={"space-between"}
                      >
                        <Grid item xs={8} md={5}>
                          <Controller
                            name="sheetOriginationAdministrationFee"
                            control={control}
                            rules={QuoteFormValidation.sheetOriginationAdministrationFee}
                            render={({ field }) => (
                              <CustomInputMultiLine
                                style={{ backgroundColor: 'white', borderRadius: '8px' }}
                                {...field}
                                type={'text'}
                                required
                                boxProps={{ my: 2 }}
                                size="small"
                                fullWidth

                                label1={"Administration Fee"}
                                label2={"(Add Compensation amount and lender origination charges)"}
                                placeholder={"eg. $1595"}
                                error={errors?.sheetOriginationAdministrationFee?.message}
                                sicon={<AttachMoney />} value={formatNumericValue(field.value)}
                                onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={8} md={5}>
                          <Controller
                            name="sheetOriginationDiscountPoints"
                            control={control}
                            rules={QuoteFormValidation.sheetOriginationDiscountPoints}
                            render={({ field }) => (
                              <CustomInput
                                style={{ backgroundColor: 'white', borderRadius: '8px' }}
                                {...field}
                                type={'text'}
                                required
                                boxProps={{ my: 2 }}
                                size="small"
                                fullWidth
                                label={"Discount Points"}
                                placeholder={"eg. $11145"}
                                error={errors?.sheetOriginationDiscountPoints?.message}
                                disabled={true}
                                sicon={<AttachMoney />} value={formatNumericValue(field.value)}
                                onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item container py={2}>
                      <Grid item>
                        <Typography variant="body2" fontWeight={"bold"}>
                          3rd Party Services (Required by the Lender)
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        container
                        direction={"row"}
                        justifyContent={"space-between"}
                      >
                        <Grid item xs={8} md={5}>
                          <Controller
                            name="sheetLenderAppraisalFee"
                            control={control}
                            rules={QuoteFormValidation.sheetLenderAppraisalFee}
                            render={({ field }) => (
                              <CustomInput
                                style={{ backgroundColor: 'white', borderRadius: '8px' }}
                                {...field}
                                type={'text'}
                                required
                                boxProps={{ my: 2 }}
                                size="small"
                                fullWidth
                                label={"Appraisal Fee"}
                                placeholder={"eg. $775"}
                                error={errors?.sheetLenderAppraisalFee?.message}
                                sicon={<AttachMoney />} value={formatNumericValue(field.value)}
                                onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={8} md={5}>
                          <Controller
                            name="sheetLenderCreditReportFee"
                            control={control}
                            rules={QuoteFormValidation.sheetLenderCreditReportFee}
                            render={({ field }) => (
                              <CustomInput
                                style={{ backgroundColor: 'white', borderRadius: '8px' }}
                                {...field}
                                type={'text'}
                                required
                                boxProps={{ my: 2 }}
                                size="small"
                                fullWidth
                                label={"Credit Report Fee"}
                                placeholder={"eg. $42"}
                                error={errors?.sheetLenderCreditReportFee?.message}
                                sicon={<AttachMoney />} value={formatNumericValue(field.value)}
                                onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        container
                        direction={"row"}
                        justifyContent={"space-between"}
                      >
                        <Grid item xs={8} md={5}>
                          <Controller
                            name="sheetLenderFloodCertificationFee"
                            control={control}
                            rules={QuoteFormValidation.sheetLenderFloodCertificationFee}
                            render={({ field }) => (
                              <CustomInput
                                style={{ backgroundColor: 'white', borderRadius: '8px' }}
                                {...field}
                                type={'text'}
                                required
                                boxProps={{ my: 2 }}
                                size="small"
                                fullWidth
                                label={"Flood Certification Fee"}
                                placeholder={"eg. $4"}
                                error={errors?.sheetLenderFloodCertificationFee?.message}
                                sicon={<AttachMoney />} value={formatNumericValue(field.value)}
                                onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={8} md={5}>
                          <Controller
                            name="sheetLenderMERSFee"
                            control={control}
                            rules={QuoteFormValidation.sheetLenderMERSFee}
                            render={({ field }) => (
                              <CustomInput
                                style={{ backgroundColor: 'white', borderRadius: '8px' }}
                                {...field}
                                type={'text'}
                                required
                                boxProps={{ my: 2 }}
                                size="small"
                                fullWidth
                                label={"MERS Fee"}
                                placeholder={"eg. $25"}
                                error={errors?.sheetLenderMERSFee?.message}
                                sicon={<AttachMoney />} value={formatNumericValue(field.value)}
                                onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        container
                        direction={"row"}
                        justifyContent={"space-between"}
                      >
                        <Grid item xs={8} md={5}>
                          <Controller
                            name="sheetLenderNewDebtMonitoring"
                            control={control}
                            rules={QuoteFormValidation.sheetLenderNewDebtMonitoring}
                            render={({ field }) => (
                              <CustomInput
                                style={{ backgroundColor: 'white', borderRadius: '8px' }}
                                {...field}
                                type={'text'}
                                required
                                boxProps={{ my: 2 }}
                                size="small"
                                fullWidth
                                label={"New Debt Monitoring"}
                                placeholder={"eg. $12"}
                                error={errors?.sheetLenderNewDebtMonitoring?.message}
                                sicon={<AttachMoney />} value={formatNumericValue(field.value)}
                                onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={8} md={5}>
                          <Controller
                            name="sheetLenderTaxServiceFee"
                            control={control}
                            rules={QuoteFormValidation.sheetLenderTaxServiceFee}
                            render={({ field }) => (
                              <CustomInput
                                style={{ backgroundColor: 'white', borderRadius: '8px' }}
                                {...field}
                                type={'text'}
                                required
                                boxProps={{ my: 2 }}
                                size="small"
                                fullWidth
                                label={"Tax Service Fee"}
                                placeholder={"eg. $69"}
                                error={errors?.sheetLenderTaxServiceFee?.message}
                                sicon={<AttachMoney />} value={formatNumericValue(field.value)}
                                onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item container pt={2}>
                      <Grid item>
                        <Typography variant="body2" fontWeight={"bold"}>
                          3rd Party Services (Title Company Charges)
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        container
                        direction={"row"}
                        justifyContent={"space-between"}
                      >
                        <Grid item xs={8} md={5}>
                          <Controller
                            name="sheetTitleCountyTransferTax"
                            control={control}
                            rules={QuoteFormValidation.sheetTitleCountyTransferTax}
                            render={({ field }) => (
                              <CustomInput
                                style={{ backgroundColor: 'white', borderRadius: '8px' }}
                                {...field}
                                type={'text'}
                                required
                                boxProps={{ my: 2 }}
                                size="small"
                                fullWidth
                                label={"County Transfer Tax"}
                                placeholder={"eg. $770"}
                                error={errors?.sheetTitleCountyTransferTax?.message}
                                sicon={<AttachMoney />} value={formatNumericValue(field.value)}
                                onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={8} md={5}>
                          <Controller
                            name="sheetTitleRecordingFee"
                            control={control}
                            rules={QuoteFormValidation.sheetTitleRecordingFee}
                            render={({ field }) => (
                              <CustomInput
                                style={{ backgroundColor: 'white', borderRadius: '8px' }}
                                {...field}
                                type={'text'}
                                required
                                boxProps={{ my: 2 }}
                                size="small"
                                fullWidth
                                label={"Recording Fee"}
                                placeholder={"eg. $159"}
                                error={errors?.sheetTitleRecordingFee?.message}
                                sicon={<AttachMoney />} value={formatNumericValue(field.value)}
                                onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        container
                        direction={"row"}
                        justifyContent={"space-between"}
                      >
                        <Grid item xs={8} md={5}>
                          <Controller
                            name="sheetTitleInsurance"
                            control={control}
                            rules={QuoteFormValidation.sheetTitleInsurance}
                            render={({ field }) => (
                              <CustomInput
                                style={{ backgroundColor: 'white', borderRadius: '8px' }}
                                {...field}
                                type={'text'}
                                required
                                boxProps={{ my: 2 }}
                                size="small"
                                fullWidth
                                label={"Title-Insurance/Lenders"}
                                placeholder={"eg. $1555"}
                                error={errors?.sheetTitleInsurance?.message}
                                sicon={<AttachMoney />} value={formatNumericValue(field.value)}
                                onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={8} md={5}>
                          <Controller
                            name="sheetTitleCourierFee"
                            control={control}
                            rules={QuoteFormValidation.sheetTitleCourierFee}
                            render={({ field }) => (
                              <CustomInput
                                style={{ backgroundColor: 'white', borderRadius: '8px' }}
                                {...field}
                                type={'text'}
                                required
                                boxProps={{ my: 2 }}
                                size="small"
                                fullWidth
                                label={"Title - Messenger/Courier Fee"}
                                placeholder={"eg. $150"}
                                error={errors?.sheetTitleCourierFee?.message}
                                sicon={<AttachMoney />} value={formatNumericValue(field.value)}
                                onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        container
                        direction={"row"}
                        justifyContent={"space-between"}
                      >
                        <Grid item xs={8} md={5}>
                          <Controller
                            name="sheetTitleMobileNotaryFee"
                            control={control}
                            rules={QuoteFormValidation.sheetTitleMobileNotaryFee}
                            render={({ field }) => (
                              <CustomInput
                                style={{ backgroundColor: 'white', borderRadius: '8px' }}
                                {...field}
                                type={'text'}
                                required
                                boxProps={{ my: 2 }}
                                size="small"
                                fullWidth
                                label={"Title-Mobile Notary Fee"}
                                placeholder={"eg. $150"}
                                error={errors?.sheetTitleMobileNotaryFee?.message}
                                sicon={<AttachMoney />} value={formatNumericValue(field.value)}
                                onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={8} md={5}>
                          <Controller
                            name="sheetTitleSettlement"
                            control={control}
                            rules={QuoteFormValidation.sheetTitleSettlement}
                            render={({ field }) => (
                              <CustomInput
                                style={{ backgroundColor: 'white', borderRadius: '8px' }}
                                {...field}
                                type={'text'}
                                required
                                boxProps={{ my: 2 }}
                                size="small"
                                fullWidth
                                label={"Title-Settlement/Escrow/Clos"}
                                placeholder={"eg. $1863"}
                                error={errors?.sheetTitleSettlement?.message}
                                sicon={<AttachMoney />} value={formatNumericValue(field.value)}
                                onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Divider />
                  </Grid>

                  <Grid item container direction={"column"}>
                    <Grid item>
                      <Typography variant="h3">
                        Pre-Paid Items/Reserves
                      </Typography>
                    </Grid>

                    <Grid item container pt={2}>
                      <Grid item>
                        <Typography variant="body2" fontWeight={"bold"}>
                          Estimated Pre-Paid Items
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        container
                        direction={"row"}
                        justifyContent={"space-between"}
                      >
                        <Grid item xs={8} md={5}>
                          <Controller
                            name="sheetPrePaidItemsPerDiemInterest"
                            control={control}
                            rules={QuoteFormValidation.sheetPrePaidItemsPerDiemInterest}
                            render={({ field }) => (
                              <CustomInput
                                style={{ backgroundColor: 'white', borderRadius: '8px' }}
                                {...field}
                                type={'text'}
                                required
                                boxProps={{ my: 2 }}
                                size="small"
                                fullWidth
                                label={"Per Diem Interest(%)"}
                                placeholder={"eg. $175"}
                                error={errors?.sheetPrePaidItemsPerDiemInterest?.message}
                                sicon={<AttachMoney />} value={formatNumericValue(field.value)}
                                onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={8} md={5}>
                          <Controller
                            name="sheetPrePaidItemsEstimatedReserves"
                            control={control}
                            rules={QuoteFormValidation.sheetPrePaidItemsEstimatedReserves}
                            render={({ field }) => (

                              <CustomTooltip
                                title="Add Homeowner’s Insurance and Real Estate Taxes calculation"
                              //  placement="top-end"
                              //     arrow


                              >
                                <div>
                                  <CustomInput
                                    style={{ backgroundColor: 'white', borderRadius: '8px' }}
                                    {...field}
                                    type={'text'}
                                    required
                                    boxProps={{ my: 2 }}
                                    size="small"
                                    fullWidth

                                    label="Estimated Reserves"

                                    placeholder={"eg. $1000"}
                                    error={errors?.sheetPrePaidItemsEstimatedReserves?.message}
                                    sicon={<AttachMoney />} value={formatNumericValue(field.value)}
                                    onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                                  />
                                </div>
                              </CustomTooltip>
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Divider />
                  </Grid>

                  <Grid item container direction={"column"}>
                    <Grid item>
                      <Typography variant="h3">Details of Transaction</Typography>
                    </Grid>
                    <Grid
                      item
                      container
                      direction={"row"}
                      justifyContent={"space-between"}
                    >
                      <Grid item xs={8} md={5}>
                        <Controller
                          name="sheetTransactionSalePrice"
                          control={control}

                          render={({ field }) => (
                            <CustomInput
                              style={{ backgroundColor: 'white', borderRadius: '8px' }}
                              {...field}
                              type={'text'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Sale Price"}
                              placeholder={"eg. $699922"}
                              error={errors?.sheetTransactionSalePrice?.message}
                              sicon={<AttachMoney />} value={formatNumericValue(field.value)}
                              onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                              disabled={true}
                            />
                          )}
                        />
                      </Grid>

                    </Grid>
                    <Grid
                      item
                      container
                      direction={"row"}
                      justifyContent={"space-between"}
                    >
                      <Grid item xs={8} md={5}>
                        <Controller
                          name="sheetTransactionReserves"
                          control={control}

                          render={({ field }) => (
                            <CustomInput
                              style={{ backgroundColor: 'white', borderRadius: '8px' }}
                              {...field}
                              type={'text'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Pre-paid Items/Reserves"}
                              placeholder={"eg. $175"}
                              error={errors?.sheetTransactionReserves?.message}
                              sicon={<AttachMoney />} value={formatNumericValue(field.value)}
                              onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                              disabled={true}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={8} md={5}>
                        <Controller
                          name="sheetTransactionIssueDate"
                          control={control}

                          render={({ field }) => (
                            <CustomInput
                              style={{ backgroundColor: 'white', borderRadius: '8px' }}
                              {...field}
                              type={'date'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Date Issued"}
                              placeholder={"25/56/2023"}
                              error={errors?.sheetTransactionIssueDate?.message}
                              disabled={true}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      direction={"row"}
                      justifyContent={"space-between"}
                    >
                      <Grid item xs={8} md={5}>
                        <Controller
                          name="sheetTransactionLoanAmount"
                          control={control}

                          render={({ field }) => (
                            <CustomInput
                              style={{ backgroundColor: 'white', borderRadius: '8px' }}
                              {...field}
                              type={'text'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Loan Amount"}
                              placeholder={"Loan Amount"}
                              error={errors?.sheetTransactionLoanAmount?.message}
                              sicon={<AttachMoney />} value={formatNumericValue(field.value)}
                              onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                              disabled={true}
                            />
                          )}
                        />

                      </Grid>
                      <Grid item xs={8} md={5}>
                        <Controller
                          name="sheetTransactionLoanTerm"
                          control={control}

                          render={({ field }) => (
                            <CustomInput
                              {...field}
                              type={'text'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Loan Term"}
                              placeholder={"convensional 30 Years"}
                              error={errors?.sheetTransactionLoanTerm?.message}
                              disabled={true}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      direction={"row"}
                      justifyContent={"space-between"}
                    >
                      <Grid item xs={8} md={5}>
                        <Controller
                          name="sheetTransactionInterestRate"
                          control={control}

                          render={({ field }) => (
                            <CustomInput
                              style={{ backgroundColor: 'white', borderRadius: '8px' }}
                              {...field}
                              type={'number'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Interest Rate (%)"}
                              placeholder={"eg. 5.23%"}
                              error={errors?.sheetTransactionInterestRate?.message}
                              disabled={true}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={8} md={5}>
                        <Controller
                          name="sheetTransactionAnnualPercentageRate"
                          control={control}

                          render={({ field }) => (
                            <CustomInput
                              style={{ backgroundColor: 'white', borderRadius: '8px' }}
                              {...field}
                              type={'number'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Annual Percentage Rate (%)"}
                              placeholder={"eg. 5.88%"}
                              error={errors?.sheetTransactionAnnualPercentageRate?.message}
                              disabled={true}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      direction={"row"}
                      justifyContent={"space-between"}
                    >
                      <Grid item xs={8} md={5}>
                        <Controller
                          name="sheetTransactionLoanToValue"
                          control={control}

                          render={({ field }) => (
                            <CustomInput
                              style={{ backgroundColor: 'white', borderRadius: '8px' }}
                              {...field}
                              type={'number'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Loan to Value (LTV)"}
                              placeholder={"eg. 80.00%"}
                              error={errors?.sheetTransactionLoanToValue?.message}
                              disabled={true}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={8} md={5}>
                        <Controller
                          name="sheetTransactionPrincipalAndInterest"
                          control={control}

                          render={({ field }) => (
                            <CustomInput
                              style={{ backgroundColor: 'white', borderRadius: '8px' }}
                              {...field}
                              type={'text'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Principal and Interest (P&I)"}
                              placeholder={"eg. $3323"}
                              error={errors?.sheetTransactionPrincipalAndInterest?.message}
                              sicon={<AttachMoney />} value={formatNumericValue(field.value)}
                              onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                              disabled={true}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      direction={"row"}
                      justifyContent={"space-between"}
                    >
                      <Grid item xs={8} md={5}>
                        <Controller
                          name="sheetTransactionOtherFinancing"
                          control={control}

                          render={({ field }) => (
                            <CustomInput
                              style={{ backgroundColor: 'white', borderRadius: '8px' }}
                              {...field}
                              type={'text'}

                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Other Financing (P&I)"}
                              placeholder={"eg. $1000"}
                              error={errors?.sheetTransactionOtherFinancing?.message}
                              sicon={<AttachMoney />} value={formatNumericValue(field.value)}
                              onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                              disabled={true}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={8} md={5}>
                        <Controller
                          name="sheetTransactionOthers"
                          control={control}

                          render={({ field }) => (
                            <CustomInput
                              style={{ backgroundColor: 'white', borderRadius: '8px' }}
                              {...field}
                              type={'text'}

                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Others"}
                              placeholder={"eg. $600"}
                              error={errors?.sheetTransactionOthers?.message}
                              sicon={<AttachMoney />} value={formatNumericValue(field.value)}
                              onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                              disabled={true}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      direction={"row"}
                      justifyContent={"space-between"}
                    >
                      <Grid item xs={8} md={5}>
                        <Controller
                          name="sheetTransactionHazardInsurance"
                          control={control}

                          render={({ field }) => (
                            <CustomInput
                              style={{ backgroundColor: 'white', borderRadius: '8px' }}
                              {...field}
                              type={'text'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Hazard Insurance/Flood"}
                              placeholder={"eg. $2000"}
                              error={errors?.sheetTransactionHazardInsurance?.message}
                              sicon={<AttachMoney />} value={formatNumericValue(field.value)}
                              onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                              disabled={true}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={8} md={5}>
                        <Controller
                          name="sheetTransactionPropertyTaxes"
                          control={control}

                          render={({ field }) => (
                            <CustomInput
                              style={{ backgroundColor: 'white', borderRadius: '8px' }}
                              {...field}
                              type={'text'}
                              required
                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Property Taxes"}
                              placeholder={"eg. $1079"}
                              error={errors?.sheetTransactionPropertyTaxes?.message}
                              sicon={<AttachMoney />} value={formatNumericValue(field.value)}
                              onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                              disabled={true}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      container
                      direction={"row"}
                      justifyContent={"space-between"}
                    >
                      <Grid item xs={8} md={5}>
                        <Controller
                          name="sheetTransactionMortgageInsurance"
                          control={control}

                          render={({ field }) => (
                            <CustomInput
                              style={{ backgroundColor: 'white', borderRadius: '8px' }}
                              {...field}
                              type={'text'}

                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"Mortgage Insurance"}
                              placeholder={"eg. $10,000"}
                              error={errors?.sheetTransactionMortgageInsurance?.message}
                              sicon={<AttachMoney />} value={formatNumericValue(field.value)}
                              onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                              disabled={true}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={8} md={5}>
                        <Controller
                          name="sheetTransactionHOADues"
                          control={control}

                          render={({ field }) => (
                            <CustomInput
                              style={{ backgroundColor: 'white', borderRadius: '8px' }}
                              {...field}
                              type={'text'}

                              boxProps={{ my: 2 }}
                              size="small"
                              fullWidth
                              label={"HOA Dues"}
                              placeholder={"eg. $500"}
                              error={errors?.sheetTransactionHOADues?.message}
                              sicon={<AttachMoney />} value={formatNumericValue(field.value)}
                              onChange={(e) => field.onChange(parseNumericValue(e.target.value))}
                              disabled={true}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Collapse>
          </Grid>
          <Grid item container md={12} justifyContent={"end"} spacing={2}>
            <Grid item>
              <Button onClick={handleClose} variant="outlined"> Cancel</Button>
            </Grid>
            <Grid item>
              <Button type="submit" variant="contained"> Preview & Save</Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
      <PreviewPDFModal
        size={'lg'}
        show={modalOpen}
        handleClose={handlePdfClose}
        title={`Generate Quote`}
        body={
          <FilePreview
            fileUrl={getFileUrl(leadId, "quote")}
            downloadPdf={downloadPdf}
          />
        }
      />
    </MainLoyOut>
  );
}
